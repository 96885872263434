import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Subscription} from 'rxjs/Subscription';

// --
import { DOCUMENT } from '@angular/common';

// Servicios de la API GALAVAIL
import {GalavailServiceEcommer} from 'clientwsGalavail';

// Servicio de Store Local data inquire
import { StoreInquireService } from '../../services/store-inquire.service';

// Servicio para setear titulo de pagina
import { TitlePagesService } from '../../services/title-pages.service';
import { environment } from 'src/environments/environment';
import { CompanyService } from 'src/app/services/company/company.service';
import { Company } from 'src/app/models/company';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styles: []
})
export class ThanksComponent implements OnInit {

  public subscription: Subscription;

  public empresa: string;
  public nombreEmpresa: string;
  public nameLogo: string;
  public colorPrimario: string;
  public colorSecundario: string;
  public colorCabecera: string;
  public colorPie: string;

  public dias: string;
  public barco: string;
  public promocion: string;
  public fechaArrivo: string;
  public fechaSalida: string;
  public duracion: string;
  public opcDuracion: string;
  public textDuracion: string;

  public todayDate: number = new Date().getFullYear();

  // Variables para titulos de datos recptados
  public textDaysCruise: string;
  public textItinerary: string;
  public textStarEndDay: string;
  public mailTo: string;

  public keyCompany: string;
  public dataCompany: Company;
  public textPromo: string;

  companyLogo=environment.appResource.companyLogos;
  logoCruise= environment.appResource.logoCruise;
  constructor(
    private activatedRoute: ActivatedRoute,
    public title: TitlePagesService,
    public storeLocalInquireService: StoreInquireService,
    @Inject(DOCUMENT) private document: Document,
    private companyService: CompanyService,
    private router: Router,
    private renderer2: Renderer2
  ) {
    this.subscription = this.activatedRoute.params.subscribe( params => {
      this.empresa = params['company'];
      this.keyCompany = params['code'];

      this.storeLocalInquireService.getLocalStorages( this.keyCompany );
    });
    this.title.changeTitlePage( this.storeLocalInquireService.getBoat(), 'thanks' );
  }

  ngOnInit() {
    this.dataCompany = this.companyService.getCompanyParams();
    if(isNullOrUndefined(this.dataCompany)){
      this.goBack();
    }
    // =============================
    // Load 279387 Sojern Tag v6_js, Pixel Version: 1
    const srcScript = this.renderer2.createElement('script');
    srcScript.type = 'text/javascript';
    srcScript.text = `
      (function () {
        console.log('>>>>> Load Script 279387');
        /* Please fill the following values. */
        var params = {
          vd1: "", /* Departure Date */
          vd2: "", /* Return Date */
          vf1: "", /* Destination City */
          vs1: "", /* Destination State or Region */
          vn1: "", /* Destination Country */
          vb: "", /* Attraction Name */
          t: "", /* Number of Travelers */
          vp: "", /* Purchase Price */
          vcu: "", /* Purchase Currency */
          vconfno: "" /* Confirmation Number */
        };

        /* Please do not modify the below code. */
        var cid = [];
        var paramsArr = [];
        var cidParams = [];
        var pl = document.createElement('script');
        var defaultParams = {"vid":"tou","et":"vc"};
        for(key in defaultParams) { params[key] = defaultParams[key]; };
        for(key in cidParams) { cid.push(params[cidParams[key]]); };
        params.cid = cid.join('|');
        for(key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])) };
        pl.type = 'text/javascript';
        pl.async = true;
        pl.src = 'https://beacon.sojern.com/pixel/p/279387?f_v=v6_js&p_v=1&' + paramsArr.join('&');
        (document.getElementsByTagName('head')[0] ||
          document.getElementsByTagName('body')[0]).appendChild(pl);
      })();
    `;
    this.renderer2.appendChild(this.document.body, srcScript);
    // =============================
    const tempDuracion = this.storeLocalInquireService.getLength().substr(0, 1) ;
    // const tempItinerary = this.opcDuracion.substr(1, 1);
    const tempItinerary = this.storeLocalInquireService.getNameIti().substr(1, 1);
    // this.textDaysCruise = tempDuracion + '-day';
    this.textDaysCruise = this.storeLocalInquireService.getLength();
    this.textItinerary = this.textDaysCruise + ' ' + tempItinerary;
    // this.textStarEndDay = this.diaDiaCrucero + ' (' + tempItinerary + ')';
    this.textStarEndDay = this.storeLocalInquireService.getStartEndDates() + ' (' + tempItinerary + ')';
    this.mailTo = this.storeLocalInquireService.getInfoMail();
    if ( this.storeLocalInquireService.getPromotion().length == 10 || this.storeLocalInquireService.getPromotion().length == 0) {
      this.textPromo = 'none';
    } else {
      this.textPromo = this.storeLocalInquireService.getPromotion();
    }
  }

  /**
   * Metodo que no ayuda a verificar si el color del pie es claro u oscuro,
   * esto se lo aplica para dar cierto estilos en el div
   * @param hexcolor
   */
  isTooDark(hexcolor: any) {
    if ( hexcolor != null ) {
      const r = parseInt(hexcolor.substr(1,2),16);
      const g = parseInt(hexcolor.substr(3,2),16);
      const b = parseInt(hexcolor.substr(4,2),16);
      const yiq = ((r * 299) + ( g * 587 ) + ( b * 114 )) / 1000;
      // Return new color if to dark, else return the original
      // return (yiq < 40) ? '#2980b9' : hexcolor;
      return (yiq < 150);
    } else {
      return false;
    }

  }
  goBack(){
    const today= new Date();
    this.router.navigate([`${this.empresa}/All/All/${today.getFullYear()}-All/1`])
  }


}
