import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'remarks'
})
export class RemarksPipe implements PipeTransform {

  transform(value: any): string {
    let retorno ="";
    if (value != null) {
      let columnas:any;
      columnas= value.split("%tr%");
      for (let i in columnas) {
        let remark = columnas[i].split("%td%");
        if(remark[1] != undefined)
          retorno += remark[1] + " ";

      }
    }
    return retorno;
  }

}
