import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MessageCopiedComponent} from './message-copied.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.css']
})
export class ShareModalComponent implements OnInit {

  public durationInSeconds = 2;

  public imageUrl: string;

  constructor(
    public dialogRef: MatDialogRef<ShareModalComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar() {
    this._snackBar.openFromComponent(MessageCopiedComponent, {
      duration: this.durationInSeconds * 1000,
    });
    this.clipboardAction();
  }

  clipboardAction() {
    this.clipboardmyMessage(this.data.dataUrl.replace(' ','%20'));
  }
  clipboardmyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getLink(sharein: string) {
    let response = '';
    switch (sharein) {
      case 'facebook': {
        response = ('https://www.facebook.com/sharer/sharer.php?u=' + this.data.dataUrl.replace(' ','%20'));
        break;
      }
      case 'twitter': {
        response = ('https://twitter.com/intent/tweet/?text=' + this.data.dataUrl.replace(' ','%20'));
        break;
      }
      case 'google': {
        response = ('https://plus.google.com/share?url=' + this.data.dataUrl.replace(' ','%20'));
        break;
      }
      case 'whatsapp': {
        response = 'https://wa.me/?text=' + encodeURIComponent(this.data.dataUrl.replace(' ','%20'));
        break;
      }
      case 'email': {
        response = 'mailto:?subject=Availability%20Galavail.&amp;body=' + encodeURIComponent(this.data.dataUrl.replace(' ','%20'));
        break;
      }
      case 'pinterest': {
        response = 'https://pinterest.com/pin/create/button/?url=' + encodeURIComponent(this.data.dataUrl.replace(' ','%20')) +
          '&media=' + encodeURIComponent(this.imageUrl) +
          '&description=' + encodeURIComponent('Availability Galavail');
        break;
      }

    }
    window.open(response);
    // return response;
  }

}
