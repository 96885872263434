import { Component, Input, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { GalavailServiceEcommer } from "clientwsGalavail";
import { NgWebflowService } from "ngweflow";
import { Subscription } from "rxjs";
import { CabinaItinerarioMenorWrapper } from "src/app/classes/cabina-itinerario-menor-wrapper";
import { PassengerType } from "src/app/enums/passenger-type.enum";
import { CabinaItinerarioMenor } from "src/app/models/cabina-itinerario-menor";
import { CamaCabinaList } from "src/app/models/cama-cabina-list";
import { Company } from "src/app/models/company";
import { Cruise } from "src/app/models/cruise.model";
import { RequestOnHold } from "src/app/models/request-onhold";
import { CabinService } from "src/app/services/cabin/cabin.service";
import { PromoService } from "src/app/services/promo.service";
import { StoreInquireService } from "src/app/services/store-inquire.service";
import { UtilsService } from "src/app/services/utils.service";
import { environment } from "src/environments/environment";
import { isNullOrUndefined } from "util";
import { RoyalRequestService } from "../form/royal-request.service";
import { OnholdService } from "./onhold.service";

export interface DialogObj {
  title: string;
  message: string;
}
@Component({
  selector: "app-onhold",
  templateUrl: "./onhold.component.html",
  styleUrls: ["./onhold.component.css"],
})
export class OnholdComponent implements OnInit {
  @Input() dataCompany: Company;
  @Input() selectedItinerary: number;
  @Input() cruise: Cruise;
  @Input() packageCode: number;
  formGroupOnHold: FormGroup;
  public cabinForms: FormGroup;
  public adults: number = 1;
  public children: number = 0;
  public numberOfPassengers: number;
  public cabinasItinerarioMenorWrapper: CabinaItinerarioMenorWrapper[];
  public selectedCabins: number;
  hasEmailRequest = false;
  codeValidationEntered = "";
  token = "";
  messageCode = "";
  codeError = "";
  isLoading = false;
  isLoadingOnHold = false;
  initialized = false;
  public subscription: Subscription;
  empresa: string;
  keyCompany: string;
  codeAvailability: string;
  isOnHold: string;
  isVisibleDialog: boolean;
  dialogObj: DialogObj;
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  geoIp = "";
  urlElementsArray: string[] = [];
  constructor(
    private fb: FormBuilder,
    private cabinService: CabinService,
    private ngWebflowService: NgWebflowService,
    private galavailServiceEcommer: GalavailServiceEcommer,
    private onholdService: OnholdService,
    private activatedRoute: ActivatedRoute,
    private storeLocalInquireService: StoreInquireService,
    private router: Router,
    private royalRequestService: RoyalRequestService,
    private promoService: PromoService
  ) {
    this.cabinasItinerarioMenorWrapper = [];
    this.isVisibleDialog = false;
    this.getDataofUrl();
  }

  ngOnInit() {
    this.royalRequestService.getGeoIpDirection().subscribe((ip) => {
      this.geoIp = ip;
    });
    this.selectedCabins = 1;
    this.numberOfPassengers = this.cruise.minimumGroupOnly;
    this.adults = this.cruise.availability[this.cruise.iterIndex].groupsOnly
      ? this.numberOfPassengers
      : 1;
    this.cabinForms = this.createCabinForms();
    this.createForm();
    this.getCabinInformation();
    this.ngWebflowService.addWebFlowAction("INIT_ON_HOLD", {
      cruise: this.cruise,
    });
  }
  getDataofUrl() {
    this.subscription = this.activatedRoute.params.subscribe((params) => {
      this.empresa = params["company"];
      this.keyCompany = params["code"];
      this.codeAvailability = params["codeAvailability"];
      this.storeLocalInquireService.getLocalStorages(this.keyCompany);
    });
  }
  get remarksValue() {
    return this.formGroupOnHold.get("remarks").value;
  }
  get referenceValue() {
    return this.formGroupOnHold.get("reference").value;
  }
  get emailValue() {
    return this.formGroupOnHold.get("email").value;
  }
  get telephoneValue() {
    return this.formGroupOnHold.get("telephone").value;
  }
  get agencyValue() {
    return this.formGroupOnHold.get("agency").value;
  }

  addCabin() {
    this.selectedCabins = +1;
  }

  createForm() {
    this.formGroupOnHold = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      reference: ["", [Validators.required]],
      remarks: [""],
      telephone: [""],
      agency: [""],
    });
  }

  public getFilteredCabinaItinerarioMenorWrapper(
    currentCabinFormIndex: number
  ): { cabina: CabinaItinerarioMenorWrapper; selected: boolean }[] {
    try {
      const filteredCabinaItinerarioMenorWrapper: {
        cabina: CabinaItinerarioMenorWrapper;
        selected: boolean;
      }[] = [];

      this.cabinasItinerarioMenorWrapper.forEach(
        (
          cabinaItinerarioMenorWrapper: CabinaItinerarioMenorWrapper,
          indexCabinasItinerarioMenor: number
        ) => {
          let isSelectedInSomeForm = false;
          this.getCabinFormsArray().controls.forEach(
            (cabinFormGroup: FormGroup, index: number) => {
              if (
                !isNullOrUndefined(
                  cabinFormGroup.get("cabinaItinerarioMenor").value
                )
              ) {
                if (
                  currentCabinFormIndex !== index &&
                  Number(cabinFormGroup.get("cabinaItinerarioMenor").value) ===
                    indexCabinasItinerarioMenor
                ) {
                  isSelectedInSomeForm = true;
                }
              }
            }
          );
          filteredCabinaItinerarioMenorWrapper.push({
            cabina: cabinaItinerarioMenorWrapper,
            selected: isSelectedInSomeForm,
          });
        }
      );
      return filteredCabinaItinerarioMenorWrapper;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public validateAllFormFields(formGroup: FormGroup) {
    console.log(formGroup);

    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  save() {
    const request = {
      email: this.emailValue,
      timeMaximumCode: this.dataCompany.timeMaximumCode,
      maximumOnHold: this.dataCompany.maximumOnHold,
      company: this.dataCompany.code
    };
    try {
      this.isLoading = true;
      this.ngWebflowService.addWebFlowAction(
        "ON_HOLD_SEND_DATA",
        { request },
        true
      );
      this.galavailServiceEcommer
        .getCodeVerification(request)
        .subscribe((resp) => {
          this.hasEmailRequest = true;
          this.token = resp.token;
          this.messageCode = resp.message;
          this.codeError = resp.code;
          this.isLoading = false;
          this.ngWebflowService.addWebFlowAction(
            "ON_HOLD_REQUEST_CODE_SUCCESS",
            { request },
            true
          );
        });
    } catch (error) {
      this.isLoading = false;

      this.ngWebflowService.addWebFlowAction(
        "ON_HOLD_REQUEST_CODE_FAILED",
        { error, request },
        true
      );
      console.error(error);
    }
  }
  setRemainingAdults() {}
  public getPrice(cabinFormIndex: number): number {
    try {
      const currentCabinaItinerarioMenorWrapper =
        this.getCurrentCabinaItinerarioMenorWrapper(cabinFormIndex);

      if (isNullOrUndefined(currentCabinaItinerarioMenorWrapper)) {
        return null;
      }
      const isIncludeCabinSupplement: boolean =
        this.isIncludeSupplementInCabin(cabinFormIndex);

      return currentCabinaItinerarioMenorWrapper.getAppPrice(
        this.cruise.category,
        false,
        isIncludeCabinSupplement,
        PassengerType.ADULT,
        this.cabinService
      );
    } catch (error) {
      console.log("SelectCabinFormComponent.getPrice()");
      console.error(error);
      return null;
    }
  }
  public getWasPrice(cabinFormIndex: number): number {
    try {
      const currentCabinaItinerarioMenorWrapper =
        this.getCurrentCabinaItinerarioMenorWrapper(cabinFormIndex);

      if (isNullOrUndefined(currentCabinaItinerarioMenorWrapper)) {
        return null;
      }
      const isIncludeSupplement: boolean =
        this.isIncludeSupplementInCabin(cabinFormIndex);
      return currentCabinaItinerarioMenorWrapper.getWasPrice(
        this.cruise.category,
        false,
        isIncludeSupplement,
        PassengerType.ADULT,
        this.cabinService
      );
    } catch (error) {
      console.error(
        "SelectCabinFormComponent.getPrice() > An error has happened: " + error
      );
      return null;
    }
  }

  public isIncludeSupplementInCabin(cabinFormIndex: number): boolean {
    try {
      const totalAdultsInCabin: number =
        this.getTotalNumerOfSelectedPassengersByCabinByType(
          cabinFormIndex,
          PassengerType.ADULT
        );
      const totalPassengersInCabin: number =
        this.getTotalNumberOfSelectedPassengersByCabin(cabinFormIndex);
      return totalAdultsInCabin === 1 && totalPassengersInCabin === 1;
    } catch (error) {
      console.log("SelectCabinFormComponent.isIncludeSupplementInCabin()");
      console.error(error);
    }
  }

  public getTotalNumerOfSelectedPassengersByCabinByType(
    indexCabinForm: number,
    passengerType: PassengerType
  ): number {
    try {
      const cabinFormGroup = this.getCabinFormsArrayElement(indexCabinForm);
      if (isNullOrUndefined(cabinFormGroup)) {
        throw new Error();
      }
      return this.getTotalNumerOfSelectedPassengersByCabinFormByType(
        cabinFormGroup,
        passengerType
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getTotalNumberOfSelectedPassengersByCabin(
    cabinFormIndex: number
  ): number {
    let numberOfSelectedPassengers = 0;
    try {
      const cabinFormGroup: FormGroup =
        this.getCabinFormsArrayElement(cabinFormIndex);
      if (isNullOrUndefined(cabinFormGroup)) {
        return 0;
      }
      numberOfSelectedPassengers =
        this.getTotalNumerOfSelectedPassengersByCabinForm(cabinFormGroup);
      return numberOfSelectedPassengers;
    } catch (error) {
      console.log(
        "SelectCabinFormComponent.getTotalNumberOfSelectedPassengersByCabin()"
      );
      console.error(error);
      throw error;
    }
  }

  public getBedTypeName(index: number): string {
    try {
      const cabinaItinerarioMenorWrapper =
        this.getCurrentCabinaItinerarioMenorWrapper(index);

      if (isNullOrUndefined(cabinaItinerarioMenorWrapper)) {
        return null;
      }

      const camaCabinaListSelected: number =
        this.getCabinFormsFormControlValue<number>(index, "camaCabinaList");
      if (isNullOrUndefined(camaCabinaListSelected)) {
        return null;
      }
      const camaCabiaList: CamaCabinaList[] =
        cabinaItinerarioMenorWrapper.getCamaCabinaList();
      if (
        isNullOrUndefined(camaCabiaList) ||
        camaCabiaList.length === 0 ||
        isNullOrUndefined(camaCabiaList[camaCabinaListSelected])
      ) {
        return null;
      }
      return camaCabiaList[camaCabinaListSelected.valueOf()].description;
    } catch (error) {
      console.log("SelectCabinFormComponent.getBedTypeName(): " + error);
      console.error(error);
      return null;
    }
  }
  public getListOfBeds(indexCabinForm: number): CamaCabinaList[] {
    try {
      const cabinaItinerarioMenorValue =
        this.getCabinFormsFormControlValue<number>(
          indexCabinForm,
          "cabinaItinerarioMenor"
        );
      if (isNullOrUndefined(cabinaItinerarioMenorValue)) {
        return [];
      }

      const itinerarioWrapper =
        this.cabinasItinerarioMenorWrapper[cabinaItinerarioMenorValue];
      if (isNullOrUndefined(itinerarioWrapper)) {
        return [];
      }

      return itinerarioWrapper.getCamaCabinaList();
    } catch (error) {
      console.error(
        "SelectCabinComponent.getListOfBeds() > An error has happened " + error
      );
      return [];
    }
  }
  public getCurrentCabinaItinerarioMenorWrapper(
    cabinFormIndex: number
  ): CabinaItinerarioMenorWrapper {
    try {
      const cabinaItinerarioMenorSelected =
        this.getCabinFormsFormControlValue<number>(
          cabinFormIndex,
          "cabinaItinerarioMenor"
        );
      if (isNullOrUndefined(cabinaItinerarioMenorSelected)) {
        return null;
      }
      return this.cabinasItinerarioMenorWrapper[cabinaItinerarioMenorSelected];
    } catch (error) {
      console.error(
        "SelectCabinFormComponent.getCurrentCabinaItinerarioMenorWrapper() > An error has happened: " +
          error
      );
      return null;
    }
  }
  private getCabinFormsFormControlValue<T>(
    index: number,
    controlName: string
  ): T {
    try {
      const cabinFormsFormControl = this.getCabinFormsFormControl(
        index,
        controlName
      );
      return isNullOrUndefined(cabinFormsFormControl)
        ? (null as T)
        : (cabinFormsFormControl.value as T);
    } catch (error) {
      console.error(error);
      return null as T;
    }
  }
  private getCabinFormsFormControl(
    index: number,
    controlName: string
  ): FormControl {
    try {
      const cabinFormsArrayElement = this.getCabinFormsArrayElement(index);
      return isNullOrUndefined(cabinFormsArrayElement)
        ? null
        : (cabinFormsArrayElement.get(controlName) as FormControl);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  private getCabinInformation(): void {
    const itinerary = this.cruise.availability[this.selectedItinerary];
    if (!itinerary) {
      return null;
    }
    try {
      const requestCabin = {
        adults: this.adults,
        children: 0,
        itinerary: itinerary.code,
        code: this.dataCompany.code,
      };
      this.galavailServiceEcommer.findCabins(requestCabin).subscribe(
        (cabinasItinerarioMenor: CabinaItinerarioMenor[]) => {
          cabinasItinerarioMenor.forEach((cabinaItinerarioMenor) => {
            cabinaItinerarioMenor.packageCode = this.packageCode;
            cabinaItinerarioMenor.percents =
              this.cruise.availability[this.selectedItinerary].percents;
            const cabinaItinerarioMenorWrapper: CabinaItinerarioMenorWrapper =
              new CabinaItinerarioMenorWrapper(cabinaItinerarioMenor);
            this.cabinasItinerarioMenorWrapper.push(
              cabinaItinerarioMenorWrapper
            );
          });
          this.initialized = true;
        },
        (error) => {
          console.error(
            "SelectCabinFormComponent.getCabinInformation() -> An error happened while de app was trying to" +
              "get the cabin information "
          );
          console.error(error);
          this.cabinasItinerarioMenorWrapper = [];
        }
      );
    } catch (error) {
      console.error(
        "Error happened in SelectCabinFormComponent.getCabinInformation() > " +
          error
      );
    }
  }

  private getCabinForms(): FormArray {
    let formGroups: FormGroup[] = [];
    if (
      this.cabinForms !== undefined &&
      this.cabinForms !== null &&
      this.cabinForms.contains("cabinForms")
    ) {
      formGroups = (this.cabinForms.get("cabinForms") as FormArray)
        .controls as FormGroup[];
      const currentNumberOfCabinForms = (
        this.cabinForms.get("cabinForms") as FormArray
      ).length;
      const differenceBetweenCurrentAndNewForm = this.selectedCabins;
      if (differenceBetweenCurrentAndNewForm > 0) {
        for (let i = 0; i < differenceBetweenCurrentAndNewForm; i++) {
          formGroups.push(this.createCabinForm());
        }
      } else if (differenceBetweenCurrentAndNewForm < 0) {
        for (let i = 0; i < differenceBetweenCurrentAndNewForm * -1; i++) {
          formGroups.pop();
        }
      }
    } else {
      for (let i = 0; i < this.selectedCabins; i++) {
        formGroups.push(this.createCabinForm());
      }
    }
    return new FormArray(formGroups);
  }

  addCabinForm() {
    if (this.cabinsFormArray.length > this.dataCompany.maximumCabinOnHold - 1) {
      this.dialogObj = {
        title: "Info",
        message: `If you require more than ${this.dataCompany.maximumCabinOnHold} cabins , please contact your Account Manager directly or e-mail ${this.dataCompany.infoEmail}`,
      };
      this.isVisibleDialog = true;
      this.ngWebflowService.addWebFlowAction("TRY_ADD_CABIN_ON_HOLD", {
        message: this.dialogObj.message,
      });
      return 0;
    }

    if (
      this.cabinasItinerarioMenorWrapper.length > this.cabinsFormArray.length
    ) {
      if (
        this.cabinForms !== undefined &&
        this.cabinForms !== null &&
        this.cabinForms.contains("cabinForms")
      ) {
        this.cabinsFormArray.push(this.createCabinForm());
        this.ngWebflowService.addWebFlowAction("TRY_ADD_CABIN_ON_HOLD", {
          message: "Added successfully",
        });
      }
    } else {
      this.dialogObj = {
        title: "Info",
        message: "There is not more cabins",
      };
      this.ngWebflowService.addWebFlowAction("TRY_ADD_CABIN_ON_HOLD", {
        message: this.dialogObj.message,
      });
      this.isVisibleDialog = true;
    }
  }
  get cabinsFormArray() {
    return this.cabinForms.get("cabinForms") as FormArray;
  }
  getArrayControls() {
    let cabinsformgroup: any = this.cabinForms.controls["cabinForms"];
    let formGroups: FormGroup[] = cabinsformgroup.controls;
    return formGroups;
  }
  cabinFormChanged() {}
  deleteCabinForm(index) {
    let cabinsformgroup: any = this.cabinForms.controls["cabinForms"];
    let formGroups: FormGroup[] = cabinsformgroup.controls;
    if (
      this.cabinForms !== undefined &&
      this.cabinForms !== null &&
      this.cabinForms.contains("cabinForms")
    ) {
      let control = this.cabinForms.get("cabinForms") as FormArray;
      this.ngWebflowService.addWebFlowAction(
        "ON_HOLD_DELETE_CABIN",
        { message: "success" },
        true
      );
      control.removeAt(index);
    }
  }

  public createCabinForm(): FormGroup {
    return new FormGroup(
      {
        cabinaItinerarioMenor: new FormControl(-1, [
          Validators.required,
          Validators.min(0),
        ]),
        camaCabinaList: new FormControl(-1),
        adults: new FormControl(
          this.storeLocalInquireService.getPaseengers()
            ? this.storeLocalInquireService.getPaseengers()
            : 1,
          Validators.required
        ),
        children: new FormControl(-1, [Validators.required, Validators.min(0)]),
      },
      this.validateCabinForm.bind(this)
    );
  }

  public validateCabinForm(cabinFormGroup: FormGroup): {
    validationError?: boolean;
    exceededCapacity?: boolean;
    childrenWithoutAdult?: boolean;
  } {
    try {
      const resultError: any = {};
      const validateCabinForm = { validationError: true };
      const cabinaItinerarioMenorValue = cabinFormGroup.get(
        "cabinaItinerarioMenor"
      ).value;
      if (isNullOrUndefined(cabinaItinerarioMenorValue)) {
        return validateCabinForm;
      }
      const cabinaItinerarioMenorWrapper: CabinaItinerarioMenorWrapper =
        this.cabinasItinerarioMenorWrapper[cabinaItinerarioMenorValue];
      if (isNullOrUndefined(cabinaItinerarioMenorWrapper)) {
        return validateCabinForm;
      }

      const selectedPassengers: number =
        this.getTotalNumerOfSelectedPassengersByCabinForm(cabinFormGroup);
      if (selectedPassengers > cabinaItinerarioMenorWrapper.getCapacidad()) {
        resultError["exceededCapacity"] = {
          capacity: cabinaItinerarioMenorWrapper.getCapacidad(),
          selectedPassengers: selectedPassengers,
        };
      }

      if (this.isChildWithOutAdultByCabinForm(cabinFormGroup)) {
        resultError["childrenWithoutAdult"] = true;
      }
      return resultError;
    } catch (error) {
      console.error(error);
      return { validationError: true };
    }
  }

  public getTotalNumerOfSelectedPassengersByCabinForm(
    cabinFormGroup: FormGroup
  ): number {
    let numberOfSelectedPassengers = 0;
    try {
      if (isNullOrUndefined(cabinFormGroup)) {
        return 0;
      }

      if (
        cabinFormGroup.contains("cabinaItinerarioMenor") &&
        !isNullOrUndefined(cabinFormGroup.get("cabinaItinerarioMenor").value)
      ) {
        if (
          cabinFormGroup.contains("adults") &&
          !isNullOrUndefined(cabinFormGroup.contains("adults")) &&
          cabinFormGroup.contains("children") &&
          !isNullOrUndefined(cabinFormGroup.contains("children"))
        ) {
          numberOfSelectedPassengers +=
            Number(cabinFormGroup.get("adults").value) +
            Number(cabinFormGroup.get("children").value);
        }
      }
      return numberOfSelectedPassengers;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  public getCabinTypeName(index: number): string {
    try {
      const cabinaItinerarioMenorWrapper =
        this.getCurrentCabinaItinerarioMenorWrapper(index);
      if (isNullOrUndefined(cabinaItinerarioMenorWrapper)) {
        return null;
      }
      return cabinaItinerarioMenorWrapper.getCabinaFullName();
    } catch (error) {
      console.error(
        "SelectCabinFormComponent.getCabinTypeName() > An error has happened while executing the method. " +
          error
      );
      return null;
    }
  }
  public isChildWithOutAdultByCabinForm(cabinFormGroup: FormGroup): boolean {
    try {
      const totalNumerOfSelectedAdults =
        this.getTotalNumerOfSelectedPassengersByCabinFormByType(
          cabinFormGroup,
          PassengerType.ADULT
        );

      const totalNumerOfSelectedChildren =
        this.getTotalNumerOfSelectedPassengersByCabinFormByType(
          cabinFormGroup,
          PassengerType.CHILD
        );

      return (
        totalNumerOfSelectedAdults === 0 && totalNumerOfSelectedChildren > 0
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getTotalNumerOfSelectedPassengersByCabinFormByType(
    cabinFormGroup: FormGroup,
    passengerType: PassengerType
  ): number {
    let numberOfSelectedPassengers = 0;
    try {
      if (isNullOrUndefined(cabinFormGroup)) {
        return 0;
      }

      if (
        cabinFormGroup.contains("cabinaItinerarioMenor") &&
        !isNullOrUndefined(cabinFormGroup.get("cabinaItinerarioMenor").value)
      ) {
        if (passengerType === PassengerType.ADULT) {
          if (
            cabinFormGroup.contains("adults") &&
            !isNullOrUndefined(cabinFormGroup.contains("adults"))
          ) {
            numberOfSelectedPassengers += Number(
              cabinFormGroup.get("adults").value
            );
          }
        } else if (passengerType === PassengerType.CHILD) {
          if (
            cabinFormGroup.contains("children") &&
            !isNullOrUndefined(cabinFormGroup.contains("children"))
          ) {
            numberOfSelectedPassengers += Number(
              cabinFormGroup.get("children").value
            );
          }
        } else {
          throw new Error();
        }
      }
      return numberOfSelectedPassengers;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public validateCabinForms(cabinFormsGroup: FormArray): {
    validationError?: boolean;
    exceededPassengers?: boolean;
    exceededAdults?: boolean;
    exceededChildren?: boolean;
    remainingPassengers?: boolean;
    remainingAdults?: boolean;
    remainingChildren?: boolean;
    repeatedCabin?: number[];
  } {
    const resultError = {};
    let totalPassengers = 0;
    let totalAdults = 0;
    let totalChildren = 0;
    const cabinaItinerarioMenorValues: number[] = [];
    try {
      const cabinFormArray: FormArray = cabinFormsGroup.get(
        "cabinForms"
      ) as FormArray;

      if (isNullOrUndefined(cabinFormArray)) {
        return { validationError: true };
      }
      cabinFormArray.controls.forEach((cabinForm: FormGroup) => {
        totalAdults += this.getTotalNumerOfSelectedPassengersByCabinFormByType(
          cabinForm,
          PassengerType.ADULT
        );
        totalChildren +=
          this.getTotalNumerOfSelectedPassengersByCabinFormByType(
            cabinForm,
            PassengerType.CHILD
          );

        const cabinaItinerarioMenorValue = cabinForm.get(
          "cabinaItinerarioMenor"
        ).value;

        for (let i = 0; i < cabinaItinerarioMenorValues.length; i++) {
          if (
            !isNullOrUndefined(cabinaItinerarioMenorValue) &&
            cabinaItinerarioMenorValues[i] === cabinaItinerarioMenorValue
          ) {
            if (isNullOrUndefined(resultError["repeatedCabin"])) {
              resultError["repeatedCabin"] = [
                Number(cabinaItinerarioMenorValues[i]),
              ];
            } else {
              const resultErrorRepeatedCabinValue: number[] =
                resultError["repeatedCabin"];
              resultErrorRepeatedCabinValue.push(
                Number(cabinaItinerarioMenorValues[i])
              );
              resultError["repeatedCabin"] = resultErrorRepeatedCabinValue;
            }
            break;
          }
        }

        if (!isNullOrUndefined(cabinaItinerarioMenorValue)) {
          cabinaItinerarioMenorValues.push(cabinaItinerarioMenorValue);
        }
      });
      totalPassengers = totalAdults + totalChildren;

      if (this.cruise.availability[this.cruise.iterIndex].groupsOnly) {
        if (totalPassengers > this.numberOfPassengers) {
          resultError["exceededPassengers"] = false;
        }
        if (totalPassengers < this.numberOfPassengers) {
          resultError["remainingPassengers"] = false;
        }
      }

      if (totalAdults > this.adults) {
        //resultError['exceededAdults'] = false;
      }

      if (totalChildren > this.children) {
        //resultError['exceededChildren'] = false;
      }

      if (totalAdults < this.adults) {
        // resultError['remainingAdults'] = false;
      }

      if (totalChildren < this.children) {
        // resultError['remainingChildren'] = false;
      }

      return resultError;
    } catch (error) {
      console.error(error);
      return { validationError: true };
    }
  }

  public createCabinForms(): FormGroup {
    this.cabinForms = new FormGroup(
      { cabinForms: this.getCabinForms() },
      this.validateCabinForms.bind(this)
    );
    return this.cabinForms;
  }

  public getNumberOfChildrenAsArray(index: number): number[] {
    try {
      const numberOfElements = this.getNumberOfPassengersForCabin(
        index,
        PassengerType.CHILD
      );
      return numberOfElements > 0
        ? UtilsService.getArrayOfNumbersByRange(0, numberOfElements)
        : [0];
    } catch (error) {
      console.error(
        "SelectCabinFormComponent.getNumberOfAdultsArray > An error has happened: " +
          error
      );
      return [];
    }
  }

  public getNumberOfPassengersForCabin(
    cabinFormIndex: number,
    passengerTpe: PassengerType
  ): number {
    try {
      const cabinFormGroup: FormGroup =
        this.getCabinFormsArrayElement(cabinFormIndex);
      return this.getNumberOfPassengersForCabinByFormGroup(
        cabinFormGroup,
        passengerTpe
      );
    } catch (error) {
      console.error(
        "SelectCabinFormComponent.getNumberOfPassengersForCabin() -> An error has happened" +
          error
      );
      throw error;
    }
  }

  public isValidRowsCabin(): boolean {
    try {
      return this.cabinsFormArray.status === "VALID" ? true : false;
    } catch (error) {
      console.error(
        "SelectCabinFormComponent.getNumberOfPassengersForCabin() -> An error has happened" +
          error
      );
      throw error;
    }
  }

  private getCabinFormsArrayElement(index: number): FormGroup {
    try {
      const cabinFormArray: FormArray = this.getCabinFormsArray();
      return isNullOrUndefined(cabinFormArray)
        ? null
        : (cabinFormArray.get(index.toString()) as FormGroup);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  private getCabinFormsArray(): FormArray {
    try {
      return isNullOrUndefined(this.cabinForms)
        ? null
        : (this.cabinForms.get("cabinForms") as FormArray);
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  public getNumberOfPassengersForCabinByFormGroup(
    cabinFormGroup: FormGroup,
    passengerType: PassengerType
  ): number {
    try {
      const cabinaItinerarioMenorSelectedValue = cabinFormGroup.get(
        "cabinaItinerarioMenor"
      ).value;
      if (isNullOrUndefined(cabinaItinerarioMenorSelectedValue)) {
        return 0;
      }
      const cabinaItinerarioMenorWrapper =
        this.cabinasItinerarioMenorWrapper[cabinaItinerarioMenorSelectedValue];
      if (isNullOrUndefined(cabinaItinerarioMenorWrapper)) {
        return 0;
      }
      if (passengerType === PassengerType.ADULT) {
        return cabinaItinerarioMenorWrapper.getCapacidadAdultos();
      } else if (passengerType === PassengerType.CHILD) {
        return cabinaItinerarioMenorWrapper.getCapacidadNinos();
      } else {
        return 0;
      }
    } catch (error) {
      console.log(
        "SelectCabinFormComponent.getNumberOfPassengersForCabinByFormGroup()"
      );
      console.error(error);
      return 0;
    }
  }

  getMainColor(): string {
    return "#" + this.dataCompany.mainColor;
  }
  getSecundaryColor(): string {
    return "#" + this.dataCompany.secondaryColor;
  }
  public getNumberOfAdultsAsArray(index: number): number[] {
    try {
      const numberOfElements = this.getNumberOfPassengersForCabin(
        index,
        PassengerType.ADULT
      );
      return numberOfElements > 0
        ? UtilsService.getArrayOfNumbersByRange(1, numberOfElements)
        : [];
    } catch (error) {
      console.error(
        "SelectCabinFormComponent.getNumberOfAdultsArray > An error has happened: " +
          error
      );
      return [];
    }
  }
  addLog(title: string) {
    this.ngWebflowService.addWebFlowAction(
      title,
      { formGroupOnHold: { ...this.formGroupOnHold.value } },
      true
    );
  }

  addLogCabins(title: string) {
    this.ngWebflowService.addWebFlowAction(
      title,
      { cabinForms: { ...this.cabinsFormArray.value } },
      true
    );
  }

  getValid(controlName): boolean {
    return (
      this.formGroupOnHold.get(controlName).invalid &&
      this.formGroupOnHold.get(controlName).touched
    );
  }

  setCodeEntered(event) {
    console.log(event);
    this.ngWebflowService.addWebFlowAction(
      "ON_HOLD_ENTERED_CODE",
      { message: event },
      true
    );
    this.codeValidationEntered = event;
  }
  blockCabin() {
    try {
      this.isLoadingOnHold = true;
      const valueRequest = this.createRequest();
      this.onholdService.onHold(valueRequest).subscribe(
        (resp) => {
          console.log(resp);
          if (resp.status) {
            this.dialogObj = {
              title: "Ok",
              message: resp.message,
            };
            this.ngWebflowService.addWebFlowAction(
              "ON_HOLD_REQUEST",
              { message: "success" },
              true
            );
            // here request CRM
            if (this.empresa === "royal") {
              this.requestRoyalForm();
            } else {
              this.redirectThanksPage();
            }
          } else {
            this.dialogObj = {
              title: "Error",
              message: resp.message,
            };
            this.ngWebflowService.addWebFlowAction(
              "ON_HOLD_REQUEST",
              { message: "Failed", request: valueRequest },
              true
            );
            this.isVisibleDialog = true;
          }
          this.isLoadingOnHold = false;
        },
        (error) => {
          this.isLoadingOnHold = false;
          console.log(error);
          this.ngWebflowService.addWebFlowAction(
            "ON_HOLD_REQUEST",
            { message: "Error", request: valueRequest, error },
            true
          );
        }
      );
    } catch (error) {
      console.error(error);
      this.isLoadingOnHold = false;
    }
  }

  async requestRoyalForm() {
    let url = this.getUrlRequest();
    console.log("url::::", url);

    this.royalRequestService.requestGetForRoyal(url).subscribe(
      (x) => {
        console.log("Observer got a next value: " + x);
        this.ngWebflowService.addWebFlowAction("CRM-ONHOLD", { ...x });
      },
      (err) => {
        this.ngWebflowService.addWebFlowAction("CRM-ONHOLD-ERROR", {
          url,
          err,
        });
        this.redirectThanksPage();
      },
      () => {
        this.redirectThanksPage();
      }
    );
  }

  getUrlRequest(): string {
    let cruiseIndex = this.cruise.iterIndex;
    const startDate = new Date(this.cruise.availability[cruiseIndex].startDate);
    const endDate = new Date(this.cruise.availability[cruiseIndex].endDate);
    const promoText =
      this.getPromoName(cruiseIndex) === "0"
        ? "Regular Rate"
        : this.getPromoName(cruiseIndex);
    const newLetter = "0";
    this.urlElementsArray = [];
    let urlFinal = environment.pathCRMroyal;

    this.getStringFormDatailRoyal(promoText);

    this.getStringTravelDataRoyal(startDate, endDate);
    this.getInterestDataRoyal();
    this.getContactInfoRoyal();
    this.getStringUtm();

    this.urlElementsArray.push(newLetter);
    this.urlElementsArray.push(this.geoIp);
    this.setLastFourItmes();
    const urlEscapedElementsArray = this.urlElementsArray.map(
      (urlElement: string) => {
        return encodeURIComponent(urlElement);
      }
    );

    urlFinal += urlEscapedElementsArray.join("/");
    return urlFinal;
  }
  setLastFourItmes() {
    this.urlElementsArray.push(this.isMobile());
    this.urlElementsArray.push("Not specific");
    this.urlElementsArray.push("Not specific");
    this.urlElementsArray.push(this.getCodeProcessByCruise());
  }

  isMobile() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "Tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "Mobile";
    } else {
      return "Desktop";
    }
  }

  getCodeProcessByCruise() {
    return `RG.${this.cruise.initials}.HOLD`;
  }

  getStringUtm(): string {
    const utms = this.royalRequestService.getUtm();
    this.urlElementsArray.push(utms.utm_source);
    this.urlElementsArray.push(utms.utm_medium);
    this.urlElementsArray.push(utms.utm_campaign);
    this.urlElementsArray.push(utms.utm_term);

    return `${utms.utm_source}/${utms.utm_medium}/${utms.utm_campaign}/${utms.utm_term}/`;
  }
  getContactInfoRoyal(): string {
    let fName = this.referenceValue;
    let lName = "none";
    let email = this.emailValue;
    let phone =
      isNullOrUndefined(this.telephoneValue) || this.telephoneValue === ""
        ? "Not specific"
        : this.telephoneValue;
    let country = "none";
    let agency = this.agencyValue === "" ? "Not specific" : this.agencyValue;
    let reference = "Not specific";
    this.urlElementsArray.push(fName);
    this.urlElementsArray.push(lName);
    this.urlElementsArray.push(email);
    this.urlElementsArray.push(phone);
    this.urlElementsArray.push(country);
    this.urlElementsArray.push(agency);
    this.urlElementsArray.push(reference);

    return `${fName}/${lName}/${email}/${phone}/${country}/${agency}/${reference}/`;
  }

  public getPromoName(itineraryIndex: number): string {
    try {
      return (
        this.promoService.getPromoText(
          this.cruise.availability[itineraryIndex].promotionType,
          this.cruise.availability[itineraryIndex].promotionValue
        ) || ""
      );
    } catch (error) {
      console.log("ResultsComponent.getPromoName()");
      console.error(error);
      return null;
    }
  }

  getInterestDataRoyal(): string {
    let interestString = "";
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");
    interestString = "0/0/0/0/";
    return interestString;
  }

  getStringTravelDataRoyal(startDate: Date, endDate: Date): string {
    let totalAdults = 0;
    let totalChildren = 0;
    const cabinFormArray: FormArray = this.cabinForms.get(
      "cabinForms"
    ) as FormArray;

    cabinFormArray.controls.forEach((cabinForm: FormGroup) => {
      totalAdults += this.getTotalNumerOfSelectedPassengersByCabinFormByType(
        cabinForm,
        PassengerType.ADULT
      );
      totalChildren += this.getTotalNumerOfSelectedPassengersByCabinFormByType(
        cabinForm,
        PassengerType.CHILD
      );
    });

    let arrivalDate = "";
    let departureDate = "";
    let daysCruise = this.storeLocalInquireService.getLength().substring(0, 1);
    let optionCruise = this.storeLocalInquireService
      .getNameIti()
      .substring(this.storeLocalInquireService.getNameIti().length, 1);
    let numberAdults = totalAdults;
    let numberChild = totalChildren;
    let comments =
      this.remarksValue === "" ? "None specific" : this.remarksValue;
    const dayString =
      startDate.getDate() < 10
        ? "0" + String(startDate.getDate())
        : String(startDate.getDate());
    const endDayString =
      endDate.getDate() < 10
        ? "0" + String(endDate.getDate())
        : String(endDate.getDate());

    arrivalDate = `${
      this.monthNames[startDate.getMonth()]
    } ${dayString}, ${startDate.getFullYear()}`;
    departureDate = `${
      this.monthNames[endDate.getMonth()]
    } ${endDayString}, ${endDate.getFullYear()}`;
    this.urlElementsArray.push(arrivalDate);
    this.urlElementsArray.push(departureDate);
    this.urlElementsArray.push(daysCruise);
    this.urlElementsArray.push(optionCruise.trim());
    this.urlElementsArray.push(String(numberAdults));
    this.urlElementsArray.push(String(numberChild));
    this.urlElementsArray.push(`${comments}- FROM ON HOLD ONLINE`);
    this.urlElementsArray.push("en");

    return `${arrivalDate}/${departureDate}/${daysCruise}/${optionCruise}/${numberAdults}/${numberChild}/${comments}/en/`;
  }

  getStringFormDatailRoyal(promoText: string) {
    let product = this.royalRequestService.getNameBoatToRoyal(
      this.storeLocalInquireService.getBoat()
    );
    let resp = "none" + "/" + product + "/" + promoText + "/";
    this.urlElementsArray.push("none");
    this.urlElementsArray.push(product);
    this.urlElementsArray.push(promoText);
  }
  createRequest(): RequestOnHold {
    let cabins = [];
    this.cabinForms.value.cabinForms.forEach((element) => {
      const index = Number(element.cabinaItinerarioMenor);
      if (index >= 0) {
        const departure = this.cabinasItinerarioMenorWrapper[index].getCode();
        const cabin = {
          departure,
          adults: element.adults,
          children: element.children,
          acomodation: element.camaCabinaList,
        };
        cabins.push(cabin);
      }
    });
    let emails: string[] = [];
    emails.push(this.dataCompany.emailEcommerce);

    return {
      token: this.token,
      validatorCode: Number(this.codeValidationEntered),
      email: this.emailValue,
      emails,
      type: 1,
      reference: `${this.referenceValue}`,
      itinerary: this.cruise.availability[0].code,
      boat: this.cruise.code.toString(),
      cabins,
      remarks:
        "Remarks: " +
        this.remarksValue +
        " Email: " +
        this.emailValue +
        " Telephone: " +
        this.telephoneValue +
        " Agency: " +
        this.agencyValue,
      aditionalServices: this.cruise.availability[0].aditionalServices,
    };
  }

  public redirectThanksPage() {
    // console.log('REDIRECCIONA A LA PAGINA DE GRACIAS');
    // this.router.navigate(['/inquire/' + this.empresa + '/' + this.keyCompany + '/thanks']);
    this.router.navigate([
      this.empresa + "/" + this.keyCompany + "/thanks-onhold",
      { boat: this.storeLocalInquireService.getBoat(), contact: "inquire" },
    ]);
  }

  setBedType(i: number) {
    try {
      let formAux = this.cabinsFormArray.at(i) as FormGroup;
      formAux.get("camaCabinaList").patchValue(this.getListOfBeds(i)[0].code);
      formAux.get("children").patchValue(0);
    } catch (error) {
      console.log(error);
    }
  }

  goBack() {
    const today = new Date();
    this.router.navigate([
      `${this.empresa}/All/All/${today.getFullYear()}-All/1`,
    ]);
  }
}
