import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FooterComponent } from "./components/footer/footer.component";
import { LoadingResultsComponent } from "./components/loading-results/loading-results.component";
import { LoadingSearcherComponent } from './components/loading-searcher/loading-searcher.component';
import { LoadingHeaderComponent } from './components/loading-header/loading-header.component';
import { LoadingFiltersComponent } from './components/loading-filters/loading-filters.component';

@NgModule({
  declarations: [FooterComponent, LoadingResultsComponent, LoadingSearcherComponent, LoadingHeaderComponent, LoadingFiltersComponent,],
  imports: [CommonModule],
  exports: [FooterComponent, LoadingResultsComponent, LoadingSearcherComponent, LoadingHeaderComponent],
})
export class CoreModule {}
