import { TipoPaqueteEcommerceEnum } from './../enums/tipo-paquete-ecommerce.enum';
import { CabinaItinerarioMenor } from '../models/cabina-itinerario-menor';
import { isNullOrUndefined } from 'util';
import { CabinService } from '../services/cabin/cabin.service';
import { PassengerType } from '../enums/passenger-type.enum';
import { environment } from '../../environments/environment';
import { SingleCabinFormCapacityType } from '../enums/single-cabin-form-capacity-type.enum';
import { CamaCabinaList } from '../models/cama-cabina-list';
import { GalavailAppStatus } from '../enums/galavail-app-status.enum';

export class CabinaItinerarioMenorWrapper {

  private cabinaItinerarioMenor: CabinaItinerarioMenor;

  public constructor(cabinaItinerarioMenor: CabinaItinerarioMenor) {
    this.setCabinaItinerarioMenor(cabinaItinerarioMenor);
  }

  public getCabinaItinerarioMenor(): CabinaItinerarioMenor {
    return this.cabinaItinerarioMenor;
  }
  // it is a method for get percent of array percents with package code
  public getAgencyPercent(): number {
    // return null; // it is for use prices machine always, delete it if you want to use arrays percents

    try {
      if (this.cabinaItinerarioMenor.percents.length !== 0) {
        // with my flag diferentA_B set type_B for get percent of array
        if (this.cabinaItinerarioMenor.percents.length === 1) {
          return Number(atob(this.cabinaItinerarioMenor.percents[0].value))
        }
        // find package code
        let valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
          === this.getPackageCode())
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_A_B)
        }
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_DEFAULT)
        }
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_B)
        }
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_A)
        }

        if (isNullOrUndefined(valPercentObj)) {
          return 0;
        }
        return Number(atob(valPercentObj.value));
      } else {
        return 0;
      }
    } catch (error) {
      return 0
    }
  }
  public getAgencyPercentOBj(): any {
    // return null; // it is for use prices machine always, delete it if you want to use arrays percents
    try {
      if (this.cabinaItinerarioMenor.percents.length !== 0) {
        // with my flag diferentA_B set type_B for get percent of array
        if (this.cabinaItinerarioMenor.percents.length === 1) {
          return (this.cabinaItinerarioMenor.percents[0])
        }
        // find package code
        let valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
          === this.getPackageCode())
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_A_B)
        }
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_DEFAULT)
        }
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_B)
        }
        if (isNullOrUndefined(valPercentObj)) {
          valPercentObj = this.cabinaItinerarioMenor.percents.find(percent => percent.packageCode
            === TipoPaqueteEcommerceEnum.TYPE_A)
        }

        if (isNullOrUndefined(valPercentObj)) {
          return {};
        }
        return (valPercentObj);
      } else {
        return {};
      }
    } catch (error) {
      return {};
    }
  }
  private getPackageCode() {
    return this.cabinaItinerarioMenor.packageCode;
  }

  private setCabinaItinerarioMenor(cabinaItinerarioMenor: CabinaItinerarioMenor): void {
    this.cabinaItinerarioMenor = cabinaItinerarioMenor;
  }

  public getCodigo(): number {
    return this.getCabinaItinerarioMenor().code;
  }

  public getCamaCabinaList(): CamaCabinaList[] {
    try {
      return this.getCabinaItinerarioMenor().accommodation;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getCamaCabinaList()');
      console.error(error);
      return null;
    }
  }

  public getCamaCabina(camaCabinaListIndex: number): CamaCabinaList {
    try {
      if (isNullOrUndefined(this.getCamaCabinaList()) || isNullOrUndefined(this.getCamaCabinaList()[camaCabinaListIndex])
        || this.getCamaCabinaList()[camaCabinaListIndex].estado === GalavailAppStatus.INACTIVE) {
        return null;
      }
      return this.getCamaCabinaList()[camaCabinaListIndex];
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getCamaCabina()');
      console.error(error);
      return null;
    }
  }

  public getValorTarifaPromocion(): number {

    try {
      if (isNullOrUndefined(this.getCabinaItinerarioMenor().promotionPublic)) {
        throw new Error('CabinaItinerarioMenorWrapper.getValorTarifaPromocion(): Null price')
      }

      return Number(this.getCabinaItinerarioMenor().promotionPublic);
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getValorTarifaPromocion()');
      console.error(error);
      throw error;
    }
  }

  public getValorTarifaAgencias(): number {
    try {
      if (isNullOrUndefined(this.getCabinaItinerarioMenor().promotionPrice)) {
        throw new Error('CabinaItinerarioMenorWrapper.getValorTarifaPromocion(): Null price')
      }
      return this.getCabinaItinerarioMenor().promotionPrice;
    } catch (error) {
      console.log('error::', this.getCabinaItinerarioMenor())
      console.log('CabinaItinerarioMenorWrapper.getValorTarifaAgencias()');
      console.error(error);
      throw error;
    }
  }

  getCode(): number{
    if (isNullOrUndefined(this.getCabinaItinerarioMenor().code)) {
      throw new Error('CabinaItinerarioMenorWrapper.getValorTarifaPromocion(): Null price')
    }
    return this.getCabinaItinerarioMenor().code
  }

  public getValorSuplemento(): number {
    try {
      if (isNullOrUndefined(this.getCabinaItinerarioMenor().supplement)) {
        throw new Error('CabinaItinerarioMenorWrapper.getValorSuplemento(): Null price')
      }
      const valueSuplement = this.getCabinaItinerarioMenor().supplement;

      return valueSuplement;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getValorSuplemento()');
      console.error(error);
      throw error;
    }
  }
  public getSglPercent() {
    try {
      return this.getCabinaItinerarioMenor().sglPercent;
    } catch (error) {
      return 0;
    }
  }

  public getValorTarifaNino(): number {
    try {
      if (isNullOrUndefined(this.getCabinaItinerarioMenor().childrenPrice)) {
        throw new Error('CabinaItinerarioMenorWrapper.getValorTarifaNino(): Null price')
      }
      return this.getCabinaItinerarioMenor().childrenPrice;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.childrenPrice()');
      console.error(error);
      throw error;
    }
  }

  public getTarifa(): number {
    try {
      return this.getCabinaItinerarioMenor().rackPrice;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getTarifa()');
      console.error(error);
      throw error;
    }
  }

  public getPromo(): number {
    try {
      return this.getCabinaItinerarioMenor().promotionType;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getPromo()');
      console.error();
      return null;
    }
  }

  public getPromoCode(): number {
    try {
      const promo = this.getPromo();
      if (isNullOrUndefined(promo)) { return null; }
      return promo;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getPromoCode()');
      console.error(error);
      return null;
    }
  }

  public getPromoPrice(cruiseCategory: number,
    includeAirFare: boolean,
    isIncludeSupplement: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService,
  ): number {
    try {
      let price = 0;
      price += this.getValorTarifaPromocion();
      if (isIncludeSupplement === true) {
        price += this.getValorSuplemento();
      }
      return this.getPrice(price, cruiseCategory, includeAirFare, passengerType, cabinaService);
    } catch (error) {
      console.log('BarcoService.getCruisePrice()');
      console.error(error);
      throw error;
    }
  }

  public getTravelAgencyPrice(cruiseCategory: number,
    includeAirFare: boolean,
    isIncludeSupplement: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService): number {
    try {
      let price = 0;
      //preguntar el valor de agencia
      price += this.getValorTarifaAgencias();
      if (isIncludeSupplement === true) {
        price += this.getValorSuplemento();
      }
      return this.getPrice(price, cruiseCategory, includeAirFare, passengerType, cabinaService);
    } catch (error) {
      console.log('BarcoService.getTravelAgencyPrice()');
      console.error(error);
      throw error;
    }
  }

  public getChildPrice(cruiseCategory: number, includeAirFare: boolean, cabinaService: CabinService): number {
    try {
      const valorTarifaNino: number = this.getValorTarifaNino();
      return this.getPrice(valorTarifaNino, cruiseCategory, includeAirFare, PassengerType.CHILD, cabinaService);
    } catch (error) {
      console.log('BarcoService.getChildPrice()');
      console.error(error);
      throw error;
    }
  }

  public getAppPrice(cruiseCategory: number,
    includeAirFare: boolean,
    isIncludeSupplement: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService,
  ): number {
    try {
      const appPrice: number = this.getTravelAgencyPrice(
        cruiseCategory, includeAirFare, isIncludeSupplement, passengerType, cabinaService);
      return appPrice;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getAppPrice()');
      console.error(error);
      throw error;
    }
  }

  public getAppPriceByPassengerType(cruiseCategory: number,
    includeAirFare: boolean,
    isIncludeSupplement: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService,
  ): number {
    try {
      const appPrice: number = this.getTravelAgencyPrice(
        cruiseCategory, includeAirFare, isIncludeSupplement, passengerType, cabinaService);
      return appPrice;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getAppPrice()');
      console.error(error);
      throw error;
    }
  }

  public getWasPrice(cruiseCategory: number,
    includeAirFare: boolean,
    isIncludeSupplement: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService): number {
    try {
      let totalPrice = 0;
      totalPrice += this.getTarifa();
      
      if (includeAirFare === true) {
       // totalPrice += cabinaService.getAirFarePriceRate(passengerType);
      }

      
      return Math.round((totalPrice));
    } catch (error) {
      console.log('BarcoService.getCruisePrice()');
      console.error(error);
      return 0;
    }
  }

  private getPrice(price: number,
    cruiseCategory: number,
    includeAirFare: boolean,
    passengerType: PassengerType,
    cabinaService: CabinService,
  ): number {
    try {
      const promoCode: number = this.getCabinaItinerarioMenor().promotionType
      return cabinaService.getAppPrice(price, cruiseCategory, promoCode, includeAirFare, passengerType, this.getAgencyPercent());
    } catch (error) {
      console.log('BarcoService.getCruisePrice()');
      console.error(error);
      throw error;
    }
  }

  public getCapacidad(): number {
    try {
      return this.getCabinaItinerarioMenor().capacity;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getCapacidad()');
      console.error(error);
      return 0;
    }
  }

  private isCapacityDynamic(): boolean {
    try {
      const type: SingleCabinFormCapacityType = environment.single_cabin_form_capacity.type;
      if (isNullOrUndefined(type)
        || type === SingleCabinFormCapacityType.FIXED
        || isNullOrUndefined(environment.single_cabin_form_capacity.capacity
          || isNaN(environment.single_cabin_form_capacity.capacity))) {
        return false;
      } else if (type === SingleCabinFormCapacityType.DYNAMIC) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.isCapacityDynamic()');
      console.error(error);
      return false;
    }
  }

  private getSingleCabinFormFixedCapacity(): number {
    try {
      return environment.single_cabin_form_capacity.capacity || 0;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getSingleCabinFormFixedCapacity()');
      console.error(error);
      return 0;
    }
  }

  private getAppCapacity(realCapacity: number): number {
    try {
      if (this.isCapacityDynamic() === true) {
        return realCapacity;
      } else {
        return this.getSingleCabinFormFixedCapacity() <= realCapacity
          ? this.getSingleCabinFormFixedCapacity() : realCapacity;
      }
    } catch (error) {
      console.log();
      console.error(error);
      return 0;
    }
  }

  public getCapacidadAdultos(): number {
    try {
      return this.getAppCapacity(this.getCabinaItinerarioMenor().capacityAdults);
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getCapacidadAdultos()');
      console.error(error);
      return 0;
    }
  }

  public getCapacidadNinos(): number {
    try {
      return this.getAppCapacity(this.getCabinaItinerarioMenor().capacityChildren);
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getCapacidadNinos()');
      console.error(error);
      return 0;
    }
  }

  public getCabinaFullName(): string {
    try {
      return this.getCabinaItinerarioMenor().deck + ' - ' + this.getCabinaItinerarioMenor().type;
    } catch (error) {
      console.log('CabinaitinerarioMenorWrapper.getCabinaFullName()');
      console.error(error);
      return null;
    }
  }

  public getFotoCabinaList(): string[] {
    try {
      return this.getCabinaItinerarioMenor().images;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getFotoCabinaList()');
      console.error(error);
      return null;
    }
  }

  public getFotoCabinaListImageNames(): string[] {
    try {
      const fotoCabinaListImageNameArray = this.getFotoCabinaList();
      return fotoCabinaListImageNameArray;
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getFotoCabinaList()');
      console.error(error);
      return [];
    }
  }

  public getFotoCabinaListMainImage(): string {
    try {
      const defaultIndex = 0;
      const fotoCabinaListImageNames: string[] = this.getFotoCabinaListImageNames();
      return fotoCabinaListImageNames[defaultIndex];
    } catch (error) {
      console.log('CabinaItinerarioMenorWrapper.getFotoCabinaListMainImage()');
      console.error(error);
      return null;
    }
  }

}
