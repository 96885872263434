import { Component, OnInit, Input } from '@angular/core';
import { Company, MenuHeader } from '../../models/company';
import { environment } from '../../../environments/environment';
import { CompanyService } from '../../services/company/company.service';
import { OptionHeaderStyleEnum } from '../../enums/option-header-style.enum';

@Component({
  selector: "app-header-core",
  templateUrl: "./header-core.component.html",
  styleUrls: ["./header-core.component.css"],
})
export class HeaderCoreComponent implements OnInit {
  @Input() dataCompany: Company;
  companyLogo = environment.appResource.companyLogos;
  menus: MenuHeader[];
  colorPrimario=""
  optionEnum = OptionHeaderStyleEnum;
  constructor(public companyService: CompanyService) {

    this.menus = [];
  }
  ngOnInit() {
    this.colorPrimario = "#" + this.dataCompany.mainColor;
    this.menus= this.companyService.getListMenu();
  }

}
