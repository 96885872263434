import { Component, Input, OnInit } from "@angular/core";
import { Company, MenuHeader } from "../../models/company";
import { environment } from "../../../environments/environment";
import { CompanyService } from "../../services/company/company.service";

@Component({
  selector: "app-header-white-brand",
  templateUrl: "./header-white-brand.component.html",
  styleUrls: ["./header-white-brand.component.css"],
})
export class HeaderWhiteBrandComponent implements OnInit {
  @Input() dataCompany: Company;
  companyLogo = environment.appResource.companyLogos;
  menus: MenuHeader[];
  colorPrimario = "";
  constructor(public companyService: CompanyService) {}

  ngOnInit() {
    this.colorPrimario = "#" + this.dataCompany.mainColor;
    this.menus = this.companyService.getListMenu();
  }
}
