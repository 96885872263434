// --
import { DOCUMENT, Location, PlatformLocation } from "@angular/common";
import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
// Servicios de la API GALAVAIL
import { GalavailServiceEcommer } from "clientwsGalavail";
import { RequestFindAvailabilityByCode } from "clientwsGalavail/src/app/modules/webservices/services/ws/galavail-ws/models/requestFindAvailabilityByCode";
// Servicios NGFlow
import { NgWebflowService } from "ngweflow";
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from "rxjs/Subscription";
import { TipoPaqueteEcommerceEnum } from "src/app/enums/tipo-paquete-ecommerce.enum";
import { Cruise } from "src/app/models/cruise.model";
import { CompanyService } from "src/app/services/company/company.service";
import { PromoService } from "src/app/services/promo.service";
import { environment } from "src/environments/environment";
import { FormModel } from "../../models/form.model";
// Servicio de Store Local data inquire
import { StoreInquireService } from "../../services/store-inquire.service";
// Servicio para setear titulo de pagina
import { TitlePagesService } from "../../services/title-pages.service";
import { ShareModalComponent } from "../results/share-modal/share-modal.component";
import { Country, RoyalRequestService } from "./royal-request.service";






@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  public registerForm: FormModel;
  private requestCompany: any;
  public dataCompany: any;
  optionA = TipoPaqueteEcommerceEnum.TYPE_A;
  passengers: number;
  children: number;

  public todayDate: number = new Date().getFullYear();
  urlElementsArray: string[] = [];
  public subscription: Subscription;
  public requestFindAvailabilityByCode: RequestFindAvailabilityByCode;
  public empresa: string;
  codeAvailability: string;
  public nombreEmpresa: string;
  public nameLogo: string;
  public colorPrimario: string;
  public colorSecundario: string;
  public colorCabecera: string;
  public colorPie: string;

  public dias: string;
  public barco: string;
  public promocion: string;
  public fechaArrivo: string;
  public fechaSalida: string;
  public duracion: string;
  public opcDuracion: string;
  public textDuracion: string;
  isLoading: boolean;
  public dateToday: Date = new Date();
  companyLogo = environment.appResource.companyLogos;
  logoCruise = environment.appResource.logoCruise;
  public listCountries: Country[] = [];

  // Variables para titulos de datos recptados
  public textDaysCruise: string;
  public textItinerary: string;
  public textStarEndDay: string;

  public showButtonSend: boolean;
  public showSending: boolean;

  public mailTo: string;

  public keyCompany: string;
  CODEPROCESS = "RG.NP.AV";
  public isOpenShareDialog: boolean;
  public urlShare: string;
  public imageUrl: string;
  public messageCopied: boolean;
  deviceInfo: any = null;
  public textPromo: string;
  geoIp = "";
  isOnHold = false;
  cruise: Cruise;
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  WELCOME_TEXT = "Inquire now about your perfect Galapagos Cruise aboard the ";
  howFindUsList: string[];
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public title: TitlePagesService,
    private galavailServiceEcommer: GalavailServiceEcommer,
    private ngWebflowService: NgWebflowService,
    public storeLocalInquireService: StoreInquireService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    public location: Location,
    public platformLocation: PlatformLocation,
    public dialog: MatDialog,
    private companyService: CompanyService,
    private royalRequestService: RoyalRequestService,
    private promoService: PromoService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.isLoading = true;
    this.listCountries = royalRequestService.getCountries();
    this.howFindUsList = royalRequestService.getHowFindUsList();
    this.getDataofUrl();
  }
  getDataofUrl() {
    this.subscription = this.activatedRoute.params.subscribe((params) => {
      this.empresa = params["company"];
      this.keyCompany = params["code"];
      this.codeAvailability = params["codeAvailability"];
      this.isOnHold = params["inquire"] === "inquire" ? false : true;
      this.storeLocalInquireService.getLocalStorages(this.keyCompany);
      this.urlShare = "";
      this.messageCopied = false;
    });
    this.registerForm = new FormModel(
      this.empresa.toString(),
      this.barco,
      this.promocion,
      this.fechaArrivo,
      this.fechaSalida,
      this.dias,
      this.duracion,
      this.opcDuracion,
      // Number(this.adultos),
      Number(this.storeLocalInquireService.getPaseengers()),
      0,
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      "",
      "",
      "",
      null,
      false,
      [],
      null,
      ""
    );
    this.title.changeTitlePage(
      this.storeLocalInquireService.getBoat(),
      "inquire"
    );
    this.showButtonSend = true;
    this.showSending = false;
  }
  getListObjectToForm(): any[] {
    return this.dataCompany.listInterest.map((item) => {
      return { description: item, status: false };
    });
  }

  ngOnInit() {
    this.generateKeyCompany();
    this.royalRequestService.getGeoIpDirection().subscribe((ip) => {
      this.geoIp = ip;
    });
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();

    this.galavailServiceEcommer.findCompany(this.requestCompany).subscribe(
      (response) => {
        this.dataCompany = response;
        this.companyService.setCompany(response);
        // console.log('EMPRESA', this.dataCompany);
        this.registerForm.listInterest = this.getListObjectToForm();
        this.mailTo = "mailto:" + this.dataCompany.infoEmail;
        this.cargarValoresPLantilla();
        this.requestFindAvailabilityByCode = {
          code: Number(this.codeAvailability),
          key: this.keyCompany,
          // agency: 'MjE3' // it should be changing
        };
        this.galavailServiceEcommer
          .findAvailabilityByCode(this.requestFindAvailabilityByCode)
          .subscribe((response) => {
            this.cruise = response[0];
            this.cruise.iterIndex = 0;
            this.storeLocalInquireService.setLogoBoat(this.cruise.logo)
            this.isLoading = false;
          });

        this.setScripts();
        this.setBodyScripts();
      },
      (error) => {
        // console.log(error.error.date);
        this.router.navigate(["error/500"]);
      }
    );

    //  switch ( this.duracion ) {
    switch (this.storeLocalInquireService.getLength()) {
      case "8D7N":
        this.textDuracion = "8 Days / 7 Nights";
        break;
      case "5D4N":
        this.textDuracion = "5 Days / 4 Nights";
        break;
      case "4D3N":
        this.textDuracion = "4 Days / 3 Nights";
        break;
    }

    // const tempDuracion = this.storeLocalInquireService.getLength().substr(0, 1);
    // const tempItinerary = this.opcDuracion.substr(1, 1);
    const tempItinerary = this.storeLocalInquireService
      .getNameIti()
      .substr(1, 1);
    // this.textDaysCruise = tempDuracion + '-day';
    this.textDaysCruise = this.storeLocalInquireService.getLength();
    this.textItinerary = this.textDaysCruise;
    // this.textStarEndDay = this.diaDiaCrucero + ' (' + tempItinerary + ')';
    this.textStarEndDay =
      this.storeLocalInquireService.getStartEndDates() +
      " (" +
      tempItinerary +
      ")";

    if (
      this.storeLocalInquireService.getPromotion().length == 10 ||
      this.storeLocalInquireService.getPromotion().length == 0
    ) {
      this.textPromo = "none";
    } else {
      this.textPromo = this.storeLocalInquireService.getPromotion();
    }

    // =============================
    // Load 279391 Sojern Tag v6_js, Pixel Version: 1
    const srcScript = this.renderer2.createElement("script");
    srcScript.type = "text/javascript";
    srcScript.text = `
      (function () {
        console.log('>>>>> Load Script 279391');
        /* Please fill the following values. */
        var params = {
          vd1: "", /* Departure Date */
          vd2: "", /* Return Date */
          vf1: "", /* Destination City */
          vs1: "", /* Destination State or Region */
          vn1: "", /* Destination Country */
          vb: "", /* Attraction Name */
          t: "" /* Number of Travelers */
        };

        /* Please do not modify the below code. */
        var cid = [];
        var paramsArr = [];
        var cidParams = [];
        var pl = document.createElement('script');
        var defaultParams = {"vid":"tou","et":"vcart"};
        for(key in defaultParams) { params[key] = defaultParams[key]; };
        for(key in cidParams) { cid.push(params[cidParams[key]]); };
        params.cid = cid.join('|');
        for(key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])) };
        pl.type = 'text/javascript';
        pl.async = true;
        pl.src = 'https://beacon.sojern.com/pixel/p/279391?f_v=v6_js&p_v=1&' + paramsArr.join('&');
        (document.getElementsByTagName('head')[0] ||
          document.getElementsByTagName('body')[0]).appendChild(pl);
      })();
    `;
    this.renderer2.appendChild(this.document.body, srcScript);
    // =============================
  }

  public setBodyScripts() {
    this.companyService.getBodyScripts().forEach((item) => {
      var body = document.createElement("script");
      body.type = "text/javascript";
      body.src = item.srcLinks;
      document.body.appendChild(body);
    });
  }
  public setScripts() {

    this.companyService.getScripts().forEach((item) => {
      var head = document.createElement("script");
      head.type = "text/javascript";
      head.src = item.srcLinks;
      document.head.appendChild(head);
    });
  }


  public cargarValoresPLantilla() {
    // console.log('********** CARGA PLANTILLA FORMULARIO');
    // console.log('empresa', this.empresa);
    this.nameLogo = "logo1.png";
    this.nombreEmpresa = this.empresa;
    this.colorPrimario = "#" + this.dataCompany.mainColor;
    this.colorSecundario = "#" + this.dataCompany.secondaryColor;
    this.colorCabecera = "#" + this.dataCompany.headerColor;
    this.colorPie = "#" + this.dataCompany.footerColor;
    // if (this.empresa === 'royal') {
    //   this.nameLogo = 'logo1.png';
    //   this.nombreEmpresa = this.empresa;
    //   this.colorPrimario = '#' + this.dataCompany.mainColor;
    //   this.colorSecundario = '#' + this.dataCompany.secondaryColor;
    //   this.colorCabecera = '#' + this.dataCompany.headerColor;
    //   this.colorPie = '#' + this.dataCompany.footerColor;
    // } else {
    //   this.router.navigate(['error/400']);
    // }
  }

  public enviarFormulario(formulario: NgForm) {
    if (formulario.valid) {
      // this.requestRoyalForm();
      if (this.empresa === "royal") {
        console.log("In Royal");
        this.showSending = true;
        this.showButtonSend = true;
        this.requestRoyalForm();
      } else {
        this.prepareSendRequest();
      }
    } else {
      console.log(
        "--------------- NO SE PUDO PROCESAR EL FORMULARIO ---------------"
      );
    }
  }

  public generateKeyCompany() {
    // const formatedDateToday: string = this.dateToday.getFullYear().toString() + '0' + (this.dateToday.getMonth() + 1) + '0' + this.dateToday.getDate();
    // const encodeDate = window.btoa(formatedDateToday);
    const encodeDate = window.btoa(this.getFormatedDateToday());
    // console.log('encodeDate', encodeDate);
    const encodeCompany = window.btoa(encodeDate + "," + this.empresa);
    // console.log('encodeCompany', encodeCompany);
    this.requestCompany = {
      key: encodeCompany,
    };
    return this.requestCompany;
  }

  public prepareSendRequest() {
    // console.log('preparate R');
    this.showButtonSend = false;
    this.showSending = true;
    const objectResquest = {
      emailReceptor: this.storeLocalInquireService.getMailCompany(),
      // emailReceptor: 'e-commerce@royalgalapagos.com',
      // emailReceptor: 'edisson@galapagosislands.com',
      subject: "New Request ",
      inquireDTO: {
        // company: this.empresa,
        company: this.dataCompany.name,
        boat: this.storeLocalInquireService.getBoat(),
        promotion: this.textPromo,
        startDate: this.storeLocalInquireService.getStartDate(),
        endDate: this.storeLocalInquireService.getEndDate(),
        days: this.storeLocalInquireService.getLength(),
        opcDays: this.storeLocalInquireService.getNameIti(),
        adults: this.registerForm.formAdultos,
        childs: this.registerForm.formNinos,
        commnets:
          this.registerForm.formComentarios === ""
            ? "None specific"
            : this.registerForm.formComentarios,
        lastName: "",
        firstName: this.registerForm.formContacto,
        email: this.registerForm.formCorreo,
        phone:
          this.registerForm.formTelefono === ""
            ? "Not specific"
            : this.registerForm.formTelefono,
        country:
          this.registerForm.formPais === ""
            ? "Not specific"
            : this.registerForm.formPais,
        interestPromos: this.registerForm.formOferta,
        agency:
          this.registerForm.formAgencia === ""
            ? "Not specific"
            : this.registerForm.formAgencia,
        reference:
          this.registerForm.formReferencia === ""
            ? "Not specific"
            : this.registerForm.formReferencia,
        charterOnly: this.storeLocalInquireService.getCharterOnly(),
        groupsOnly: this.storeLocalInquireService.getGroupsOnly(),
        ipSender: this.geoIp,
        howFindUs:
          this.registerForm.howFindUs === ""
            ? "Not specific"
            : this.registerForm.howFindUs === "Other"
            ? this.registerForm.valueOther
            : this.registerForm.howFindUs,
      },
      company: {
        logoCompany: this.dataCompany.logo,
        nameCompany: this.dataCompany.name,
        mainColorCompany: this.dataCompany.mainColor,
        secondaryColorCompany: this.dataCompany.secondaryColor,
        headerColorCompany: this.dataCompany.headercolor,
        footerColorCompany: this.dataCompany.footerColor,
        infoMailCompany: this.dataCompany.infoEmail,
        phoneCompany: this.dataCompany.phone,
        webSiteCompany: this.dataCompany.webSite,
      },
      listInterest: this.registerForm.listInterest.map((item) => {
        return {
          description: item.description,
          status: item.status ? "Yes" : "No",
        };
      }),
      aditionalServicesList:
        this.storeLocalInquireService.getAditionalServices().length === 0
          ? ["None specific"]
          : this.storeLocalInquireService.getAditionalServices(),
    };

    this.galavailServiceEcommer.sendInquireEmail(objectResquest).subscribe(
      (res) => {
        this.ngWebflowService.addWebFlowAction(
          "INQUIRE-AVAILABILITY",
          objectResquest
        );
        // console.log(res);
        this.redirectThanksPage();
      },
      (error) => {
        console.log("DONT SEND");
        console.log(error);
      }
    );
  }

  public redirectThanksPage() {
    // console.log('REDIRECCIONA A LA PAGINA DE GRACIAS');
    // this.router.navigate(['/inquire/' + this.empresa + '/' + this.keyCompany + '/thanks']);
    this.router.navigate([
      this.empresa + "/" + this.keyCompany + "/thanks",
      { boat: this.storeLocalInquireService.getBoat(), contact: "inquire" },
    ]);
  }

  async requestRoyalForm() {
    this.showSending = true;
    let url = this.getUrlRequest();
    this.royalRequestService.requestGetForRoyal(url).subscribe(
      (x) => {
        this.ngWebflowService.addWebFlowAction("CRM-INQUIRE", { ...x });
        // console.log(res);
      },
      (err) => {
        this.ngWebflowService.addWebFlowAction("CRM-INQUIRE-ERROR", {
          url,
          err,
        });
        this.prepareSendRequest();
      },
      () => {
        this.prepareSendRequest();
      }
    );
  }

  getUrlRequest(): string {
    let cruiseIndex = this.cruise.iterIndex;
    const startDate = new Date(this.cruise.availability[cruiseIndex].startDate);
    const endDate = new Date(this.cruise.availability[cruiseIndex].endDate);
    const promoText =
      this.getPromoName(cruiseIndex) === "0"
        ? "Regular Rate"
        : this.getPromoName(cruiseIndex);
    const newLetter = this.registerForm.formOferta ? "1" : "0";

    this.urlElementsArray = [];
    let urlFinal = environment.pathCRMroyal;

    this.getStringFormDatailRoyal(promoText);

    this.getStringTravelDataRoyal(startDate, endDate);
    this.getInterestDataRoyal();
    this.getContactInfoRoyal();
    this.getStringUtm();
    this.urlElementsArray.push(newLetter);
    const ip = this.geoIp === "" ? "none" : this.geoIp;
    this.urlElementsArray.push(ip);
    this.pushHowFindUstoArray();
    const urlEscapedElementsArray = this.urlElementsArray.map(
      (urlElement: string) => {
        return encodeURIComponent(urlElement);
      }
    );

    urlFinal += urlEscapedElementsArray.join("/");
    return urlFinal;
  }

  pushHowFindUstoArray() {
    const howFindUs: string =
      this.registerForm.howFindUs === ""
        ? "Not specific"
        : this.registerForm.howFindUs;

    const otherValue: string =
      this.registerForm.howFindUs === "Other"
        ? this.registerForm.valueOther
        : "none";
    const deviceStringInfo = this.isMobile();

    this.urlElementsArray.push(deviceStringInfo);
    this.urlElementsArray.push(howFindUs);
    this.urlElementsArray.push(otherValue === "" ? "none" : otherValue);
    this.urlElementsArray.push(this.getCodeProcessByCruise());
  }

  getCodeProcessByCruise() {
    return `RG.${this.cruise.initials}.AV`;
  }

  isMobile() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "Tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "Mobile";
    } else {
      return "Desktop";
    }
  }

  getStringUtm(): string {
    const utms = this.royalRequestService.getUtm();
    this.urlElementsArray.push(utms.utm_source);
    this.urlElementsArray.push(utms.utm_medium);
    this.urlElementsArray.push(utms.utm_campaign);
    this.urlElementsArray.push(utms.utm_term);
    return `${utms.utm_source}/${utms.utm_medium}/${utms.utm_campaign}/${utms.utm_term}/`;
  }
  getStringFormDatailRoyal(promoText: string): string {
    let product = this.royalRequestService.getNameBoatToRoyal(
      this.storeLocalInquireService.getBoat()
    );
    let resp = "none" + "/" + product + "/" + promoText + "/";
    this.urlElementsArray.push("none");
    this.urlElementsArray.push(product);
    this.urlElementsArray.push(promoText);
    // let resp = (services === "" ? "none" : services.substring(0, services.length - 1)) + "/" + product + "/" + promoText + "/";
    return resp;
  }

  getStringTravelDataRoyal(startDate: Date, endDate: Date): string {
    let arrivalDate = "";
    let departureDate = "";
    let daysCruise = this.storeLocalInquireService.getLength().substring(0, 1);
    let optionCruise = this.storeLocalInquireService
      .getNameIti()
      .substring(this.storeLocalInquireService.getNameIti().length, 1);
    let numberAdults = this.registerForm.formAdultos;
    let numberChild = this.registerForm.formNinos;
    let comments =
      this.registerForm.formComentarios === ""
        ? "None specific"
        : this.registerForm.formComentarios;
    const dayString =
      startDate.getDate() < 10
        ? "0" + String(startDate.getDate())
        : String(startDate.getDate());
    const endDayString =
      endDate.getDate() < 10
        ? "0" + String(endDate.getDate())
        : String(endDate.getDate());

    arrivalDate = `${
      this.monthNames[startDate.getMonth()]
    } ${dayString}, ${startDate.getFullYear()}`;
    departureDate = `${
      this.monthNames[endDate.getMonth()]
    } ${endDayString}, ${endDate.getFullYear()}`;
    this.urlElementsArray.push(arrivalDate);
    this.urlElementsArray.push(departureDate);
    this.urlElementsArray.push(daysCruise);
    this.urlElementsArray.push(optionCruise.trim());
    this.urlElementsArray.push(String(numberAdults));
    this.urlElementsArray.push(String(numberChild));
    this.urlElementsArray.push(`${comments}- FROM INQUIRE OF ROYAL`);
    this.urlElementsArray.push("en");

    return `${arrivalDate}/${departureDate}/${daysCruise}/${optionCruise}/${numberAdults}/${numberChild}/${comments}/en/`;
  }

  getInterestDataRoyal(): string {
    let interestString = "";
    interestString =
      (this.registerForm.listInterest[2].status ? "1" : "0") +
      "/" +
      (this.registerForm.listInterest[0].status ? "1" : "0") +
      "/0/" +
      (this.registerForm.listInterest[1].status ? "1" : "0") +
      "/";

    this.urlElementsArray.push(
      this.registerForm.listInterest[2].status ? "1" : "0"
    );
    this.urlElementsArray.push(
      this.registerForm.listInterest[0].status ? "1" : "0"
    );
    this.urlElementsArray.push("0");
    this.urlElementsArray.push(
      this.registerForm.listInterest[1].status ? "1" : "0"
    );

    return interestString;
  }

  getContactInfoRoyal(): string {
    let fName = this.registerForm.formContacto;
    let lName = "none";
    let email = this.registerForm.formCorreo;
    let phone =
      this.registerForm.formTelefono === ""
        ? "Not specific"
        : this.registerForm.formTelefono;
    let country =
      this.registerForm.formPais === ""
        ? "Not specific"
        : this.registerForm.formPais;
    let agency =
      this.registerForm.formAgencia === ""
        ? "Not specific"
        : this.registerForm.formAgencia;
    let reference =
      this.registerForm.formReferencia === ""
        ? "Not specific"
        : this.registerForm.formReferencia;
    this.urlElementsArray.push(fName);
    this.urlElementsArray.push(lName);
    this.urlElementsArray.push(email);
    this.urlElementsArray.push(phone);
    this.urlElementsArray.push(country);
    this.urlElementsArray.push(agency);
    this.urlElementsArray.push(reference);

    return `${fName}/${lName}/${email}/${phone}/${country}/${agency}/${reference}/`;
  }

  public getPromoName(itineraryIndex: number): string {
    try {
      return (
        this.promoService.getPromoText(
          this.cruise.availability[itineraryIndex].promotionType,
          this.cruise.availability[itineraryIndex].promotionValue
        ) || ""
      );
    } catch (error) {
      console.log("ResultsComponent.getPromoName()");
      console.error(error);
      return null;
    }
  }

  getFormatedDateToday() {
    let formatedDateToday: string;
    const tempMonth: number = this.dateToday.getMonth() + 1;
    const tempDay: number = this.dateToday.getDate();

    let formatMonth: string;
    let formatDay: string;

    if (tempMonth > 0 && tempMonth < 10) {
      formatMonth = "0" + tempMonth;
    } else {
      formatMonth = tempMonth.toString();
    }
    if (tempDay > 0 && tempDay < 10) {
      formatDay = "0" + tempDay;
    } else {
      formatDay = tempDay.toString();
    }

    formatedDateToday =
      this.dateToday.getFullYear().toString() + formatMonth + formatDay;

    return formatedDateToday;
  }

  renderTextPromotion(promo: string) {
    let textPromo: string;
    let countTextPromo: number;
    textPromo = promo.length.toString();

    return textPromo;
  }

  /**
   * Metodo que no ayuda a verificar si el color del pie es claro u oscuro,
   * esto se lo aplica para dar cierto estilos en el div
   * @param hexcolor
   */
  isTooDark(hexcolor: any) {
    if (hexcolor != null) {
      const r = parseInt(hexcolor.substr(1, 2), 16);
      const g = parseInt(hexcolor.substr(3, 2), 16);
      const b = parseInt(hexcolor.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      // Return new color if to dark, else return the original
      // return (yiq < 40) ? '#2980b9' : hexcolor;
      return yiq < 150;
    } else {
      return false;
    }
  }

  // *********************************************************
  // *** Metodo para mostrar modal compartir
  /**
   *
   * @param dataBoat
   * @param dataDeparture
   */
  openDialogShare(): void {
    let splitUrlOrigin: any;
    let fecha: string;
    splitUrlOrigin = this.storeLocalInquireService.getUrlOrigin().split("/");
    fecha = splitUrlOrigin[3];
    this.urlShare =
      "https://" +
      (this.platformLocation as any).location.host +
      "/" +
      this.storeLocalInquireService.getCompany() +
      "/" +
      this.storeLocalInquireService.getBoat() +
      "/" +
      this.storeLocalInquireService.getLength().substr(0, 1) +
      "/" +
      fecha +
      "/" +
      this.storeLocalInquireService.getPaseengers();
    const dialogRef = this.dialog.open(ShareModalComponent, {
      data: {
        dataUrl: this.urlShare,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
  // *********************************************************
  goBack() {
    const today = new Date();
    this.router.navigate([
      `${this.empresa}/All/All/${today.getFullYear()}-All/1`,
    ]);
  }
  getHeaderTitle(): string {
    return this.isOnHold
      ? "Galapagos Cruise online hold"
      : "Inquire about a Galapagos Cruise";
  }
  
}
