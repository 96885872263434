import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GalavailRestService } from 'clientwsGalavail';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnholdService {

  constructor(private galavailRestService: GalavailRestService) { }

  public onHold(requestOnhold: any): Observable<any> {
    const httpHeaders: HttpHeaders = new HttpHeaders({});
    const restServiceItemName = 'requestG_blockCabin';
    return this.galavailRestService.request<any>(null,
      restServiceItemName, { headers: httpHeaders }, requestOnhold,requestOnhold.email);
  }
}
