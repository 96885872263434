import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, NgForm } from '@angular/forms';
// Servicios de la API GALAVAIL
import { GalavailServiceEcommer } from 'clientwsGalavail';
import { Catalogue } from 'src/app/models/list-year-month';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company/company.service';
import { CruiseService } from 'src/app/services/cruise.service';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styles: []
})
export class SearchComponent implements OnInit {

  @Output() valuesChange = new EventEmitter();
  @Output() resetValueContentResult = new EventEmitter();
  @Output() resetShowLoading = new EventEmitter();

  @Input() childExample: string;
  @Input() colorMain: string;
  @Input() listYearMonth: Catalogue[];
  @Input() cataloguePax: Catalogue[];
  @Input() catalogueItinerary: Catalogue[];
  @Input() colorSecundario: string;
  @Input() dataCompany: Company;
  @Input() isSearching: boolean;

  CHARTERPROMO = -1;
  public promotionsList = [
    { label: '2 x 1', value: '2567' },
    { label: '$ OFF', value: '2563' },
    { label: '% OFF', value: '2564' },
    { label: 'Super Promo', value: '2' },
    { label: 'Promotions Only', value: '1' },
  ];
  public parametro: any;
  public dataResponse: any = [];
  public listBoats: any[] = [];
  public listCategories: any[] = [];

  public tempNameEmpresa: string;
  visibleAdvanceSearch = false;
  buscador: any = {
    empresa: null,
    barco: null,
    dias: null,
    fecha: {},
    pasajeros: null,
    price: 10000,
    promotion: 'All',
    filter: 'All',
    valores: [],
    promotions: [],
    groupsOnly: false,
    charterOnly: false

  };
  selectedPromotions = new FormControl();
  listYears = [];

  listadoMeses: any[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  listadoMesesn: any[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  listMonthComplete = ['All', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  listMonth = [];
  anioActual: number = new Date().getFullYear();
  mesActual: number = new Date().getMonth();
  textoMensajeBusqueda: boolean;
  labelAllDays: string;

  today = new Date();
  monthCurrent = this.today.getMonth();
  isCharterOnly: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private galavailServiceEcommer: GalavailServiceEcommer,
    public companyService: CompanyService,
    private cruiseService: CruiseService
  ) {
  this.visibleAdvanceSearch = false;
  }

  ngOnInit() {
    // console.log('RECIBE PARAMETRO CODIGO EMPRESA:', this.childExample);
    // console.log('RECIBE PARAMETRO CODIGO COLOR MAIN EMPRESA:', this.colorMain);
    // this.visibleAdvanceSearch =
    //   this.promotionsList.length > 0 || this.dataCompany.visibleCharterOnly;
    this.setDataofUrl();
    this.labelAllDays = '';
    this.setPromotionsLinst();

    for (let i in this.catalogueItinerary) {
      if (this.catalogueItinerary[i] === this.catalogueItinerary[this.catalogueItinerary.length - 1]) {
        this.labelAllDays += this.catalogueItinerary[i].description;
      } else {
        this.labelAllDays += this.catalogueItinerary[i].description + ', ';
      }
    }

    for (const p in this.cataloguePax) {
      if (this.cataloguePax[p].code > '1') {
        this.cataloguePax[p].description = this.cataloguePax[p].code + ' Passengers';
      }
    }
  }

  setPromotionsLinst() {
    this.promotionsList = [];
    if (this.dataCompany) {
      this.dataCompany.listPromotions.forEach(element => {
        if (element.status) {

          this.promotionsList.push(element)
        }
      });
    }
  }
  setDataofUrl() {
    this.activatedRoute.params.subscribe(params => {
      const auxdate = params['monthYear'] ? params['monthYear'].split('-') : '';
      const today = new Date();
      this.isCharterOnly = params['paxs'] === 'charterOnly';
      if (this.isCharterOnly) {
        this.selectedPromotions.setValue([this.CHARTERPROMO]);
      }

      this.buscador = {
        empresa: params['company'],
        barco: params['boat'] ? params['boat'] : 'All',
        dias: params['days'] ? params['days'] : 'All',
        pasajeros: params['paxs'] ? this.validatePassenger(params['paxs']) : this.getAllPassenger(),
        fecha: {
          month: auxdate[1] ? auxdate[1] : 'All',
          year: auxdate[0] ? auxdate[0] : today.getFullYear(),
        },
        price: 0,
        promotion: 'All',
        filter: 'All',
        promotions: [],
        groupsOnly: false,
        charterOnly: this.isCharterOnly
      };
      this.fillYearsAndMonth();
      // console.log('BUSCADOR', this.buscador);
      this.getListBoats(this.childExample);
      this.tempNameEmpresa = this.buscador.empresa;
    });
  }

  fillYearsAndMonth() {
    this.listYears = this.listYearMonth.map(item => {
      return item.code;
    });

    this.listMonth = [];

    if (this.buscador.fecha.year == this.today.getFullYear()) {
      const tempMonths = this.listadoMeses.slice(this.monthCurrent, 12);
      this.listMonth = tempMonths;
    } else {
      this.listMonth = this.listadoMeses;
    }

  }
  changeYear() {
    this.listMonth = [];

    if (this.buscador.fecha.year == this.today.getFullYear()) {
      const tempMonths = this.listadoMeses.slice(this.monthCurrent, 12);
      this.listMonth = tempMonths;
    } else {
      this.listMonth = this.listadoMeses;
    }
  }
  setDefaultValue() {
    const today = new Date();
    this.activatedRoute.params.subscribe(params => {
      this.buscador = {
        empresa: params['company'],
        barco: 'Boats',
        dias: 'Days',
        pasajeros: 'Paxs',
        fecha: {
          month: 'Month',
          year: today.getFullYear(),
        },
        price: 0,
        promotion: 'All',
        filter: 'All'
      };
    });
    // console.log('BUSCADOR', this.buscador);
    // this.listBoats = [];
    // this.getListBoats(this.childExample);
    this.tempNameEmpresa = this.buscador.empresa;
  }

  public buscarSalidas(forma: NgForm): void {
    // console.log(forma);
    if(this.isSearching){
      return ;
    }
    if ((forma.value.barco !== 'Boats') && (forma.value.dias !== 'Days') && (forma.value.fecha !== 'Month')
      && (forma.value.selyear !== 'Year') && (forma.value.paxs !== 'Paxs')) {

      // console.log('barco:', forma.value);
      // console.log('dias:', forma.value.dias);
      // console.log('fecha:', forma.value.fecha);
      // console.log('paxs:', forma.value.paxs);
      const yearAux = forma.value.selyear + '-' + forma.value.fecha;
      this.textoMensajeBusqueda = false;
      this.isCharterOnly = (forma.value.paxs === 'charterOnly');


      this.setUrlOrigin();
      this.valuesChange.emit({
        empresa: this.tempNameEmpresa,
        barco: forma.value.barco,
        dias: forma.value.dias,
        pasajeros: this.isCharterOnly ? this.getAllPassenger() : forma.value.paxs,
        fecha: yearAux,
        price: 0,
        promotion: 'All',
        filter: 'All',
        promotions: this.selectedPromotions.value ? this.getOnlyPromosSelected() : [],
        charterOnly: this.isCharterOnly,
        groupsOnly: this.buscador.groupsOnly,

      });
      this.buscador.fecha.month = forma.value.fecha;
      this.resetShowLoading.emit(true);
      this.resetValueContentResult.emit(false);
      this.router.navigate([this.buscador.empresa, forma.value.barco, forma.value.dias, yearAux,
      ((forma.value.paxs === "charterOnly" || (this.isSelectedCharterPromo() && forma.value.paxs === "charterOnly"))? "charterOnly" : forma.value.paxs)]);


    } else {
      this.textoMensajeBusqueda = true;
      // this.resetValueContentResult.emit(false);
    }
  }

  onChangePassenger(){

    if(this.buscador.pasajeros==="charterOnly"){
      this.selectedPromotions.setValue([this.CHARTERPROMO])
    }else {
      this.selectedPromotions.setValue([])
    }
    
  }

  getOnlyPromosSelected() {
    const auxArray: [] = this.selectedPromotions.value;
    let resp = []
    auxArray.forEach(item => {
      if (item !== this.CHARTERPROMO) {
        resp.push(item)
      }
    });
    return resp;
  }

  public getListBoats(codigo: string) {
    this.parametro = {
      code: codigo
    };
    if (this.listCategories.length === 0 || this.listBoats.length === 0) {

      this.galavailServiceEcommer.getFleetByCodeCompany(this.parametro).subscribe(response => {
        this.dataResponse = response;
        // console.log('DATO BARCOS>>>', this.dataResponse)
        let countLux = 0;
        let countFirst = 0;
        let countMid = 0;
        let countBudget = 0;

        for (let data of this.dataResponse) {
          const item = {
            codeBoat: data.codigo,
            nameBoat: data.nombre,
            startBoat: data.estrellas,
            catBoat: data.categoria
          };
          this.listBoats.push(item);

          switch (data.categoria) {
            case 5:
              countLux++;
              break;
            case 4:
              countFirst++;
              break;
            case 3:
              countMid++;
              break;
            case 2:
              countBudget++;
          }
        }
        this.cruiseService.setCruisesList(this.listBoats);
        // console.log('listado barcos', this.listBoats);
        if (countLux > 0) {
          const itemCat = {
            nameCategory: 'Luxury',
            valueCategory: '5',
            startCategory: '★★★★★'
          };
          this.listCategories.push(itemCat);
        }
        if (countFirst > 0) {
          const itemCat = {
            nameCategory: 'First Class',
            valueCategory: '4',
            startCategory: '★★★★'
          };
          this.listCategories.push(itemCat);
        }
        if (countMid > 0) {
          const itemCat = {
            nameCategory: 'Mid Range',
            valueCategory: '3',
            startCategory: '★★★'
          };
          this.listCategories.push(itemCat);
        }
        if (countBudget > 0) {
          const itemCat = {
            nameCategory: 'Budget',
            valueCategory: '2',
            startCategory: '★★'
          };
          this.listCategories.push(itemCat);
        }
      });
    }

    // console.log('listado Categorias', this.listCategories);
  }

  public setUrlOrigin() {
    this.galavailServiceEcommer.setLocalStorage('urlOrigen', this.buscador.empresa + '/' + this.buscador.barco + '/' + this.buscador.dias + '/' + this.buscador.fecha + '/' + this.buscador.pasajeros);
  }

  validatePassenger(valuePax: string) {

    return this.isCharterOnly ? "charterOnly" : Number(valuePax);
  }


  getAllPassenger(): string {

    let index = this.cataloguePax.length
    // return index >0 ? this.cataloguePax[index-1].code : "1";
    return "1";
  }

  isSelectedCharterPromo(): boolean {
    const auxArray: [] = this.selectedPromotions.value;
    let index = -1;
    try {

      index = auxArray.findIndex(item => item === this.CHARTERPROMO)
    } catch (error) {
      index = -1;
    }

    return index >= 0 ? true : false;
  }

  onChangePromotions(){
    if (this.isSelectedCharterPromo()) {
      this.buscador.pasajeros= "charterOnly"
    }
  }

}
