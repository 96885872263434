import { Injectable, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import {isNullOrUndefined} from 'util';
import {Observable} from 'rxjs/Observable';
import {TimerObservable} from 'rxjs/observable/TimerObservable';
import { MonthDay } from '../models/month-day';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }
  public linked: any = {};
  public utilsService: EventEmitter<any> = new EventEmitter<any>();

  public static getDate( fullYear: number, month: number, day: number ): Date {
    try {
      if ( isNullOrUndefined( fullYear ) || isNullOrUndefined( month ) || isNullOrUndefined( day )
        || ( month < 1 || month > 12 ) || ( day < 1 || day > 31 ) ) { return null; }
      return new Date( fullYear, month - 1, day );
    } catch ( error ) {
      console.log( 'UtilsService.getDate()' );
      console.error( error );
      return null;
    }
  }

  /***
   * Given a year and a month, it returns the number of days of it.
   * @param {number} month Month number (froom 1 to 12: 1 for January, 2 for February, etc.)
   * @param {number} year Year number
   * @returns {number} A number which represents the number of days of the given month/year combination
   */
  public static getNumberOfDaysForMonth ( month: number, year: number ): number {
    try {
      return new Date(year, month, 0).getDate();
    } catch ( error ) {
      console.log ('UtilService.getNumberOfDaysForMonth()');
      console.error ( error );
      return 0;
    }
  }

  public static getOrdinalNumber( n ): string {
    try {
      const s: string[] = [ 'th', 'st', 'nd', 'rd' ];
      const v: number = n % 100;
      return n + ( s[ ( v - 20 ) % 10 ] || s[ v ] || s[ 0 ]);
    } catch ( error ) {
      console.log('UtilService.getOrdinalNumber()');
      console.error( error );
      return null;
    }
  }

  /***
   * Given a string with the format yyyy-M (Ex, 2016-5 ), it generates a Date object for the given parameter
   * @param {string} stringDate a string with the format yyyy-M (Ex, 2016-5 )
   * @returns {Date} A date object for the year and month sent
   */
  public static generateDateByStringYearAndMonthDate( stringDate: string ): Date {
    const dateArray = stringDate.split('-');
    if ( dateArray.length >= 2 ) {
      const year = Number( dateArray[0] );
      const month = Number( dateArray[1] );
      if ( ( Number.isSafeInteger( year ) && Number.isSafeInteger( month ) ) && ( month >= 1 && month <= 12 ) ) {
        return UtilsService.generateDateByYearAndMonth( year, month );
      }
    }
    return null;
  }
  /***
   * Given a string with the format yyyy-M (Ex, 2016-5 ), it generates a Date object for the given parameter
   * @param {string} stringDate a string with the format yyyy-M (Ex, 2016.5 )
   * @returns {Date} A date object for the year and month sent
   */
  public static generateDateByStringYearAndMonthDateforPoint( stringDate: string ): Date {
    const dateArray = stringDate.split('.');
    if ( dateArray.length >= 2 ) {
      const year = Number( dateArray[0] );
      const month = Number( dateArray[1] );
      if ( ( Number.isSafeInteger( year ) && Number.isSafeInteger( month ) ) && ( month >= 1 && month <= 12 ) ) {
        return UtilsService.generateDateByYearAndMonth( year, month );
      }
    }
    return null;
  }

  /***
   * Given a year and a month, it generates a Date object from those values
   * @param {number} year the year that will be set in the date object
   * @param {number} month the month (1 to 12) to be set in the date object
   * @returns {Date} A date object for the given month and year
   */
  public static generateDateByYearAndMonth(year: number, month: number): Date {
    return new Date( year, month - 1 );
  }

  public static generateDateByMiliseconds( milisecondsDate: number ): Date {
    try {
      return new Date( milisecondsDate );
    } catch ( error ) {
      console.log('');
      console.error( error );
      return null;
    }
  }

  public static millisecondsToHours( milliseconds: number ): number {
    try {
      return  milliseconds / 1000 / 60 / 60;
    } catch ( error ) {
      console.log( 'UtilService.millisecondsToHours()' );
      console.error( error );
      throw Number;
    }
  }

  public static millisecondsToDays( milliseconds: number ): number {
    try {
      return  milliseconds / 1000 / 60 / 60 / 24;
    } catch ( error ) {
      console.log( 'UtilService.millisecondsToHDays()' );
      console.error( error );
      throw Number;
    }
  }

  public static generateFormattedDateMMYYYY( date: Date ): string {
    return UtilsService.generateFormattedDate( date, 'MMMM, YYYY' );
  }

  public static generateFormattedDate( date: Date, format: string): string {
    return moment( date ).format( format );
  }

  public static getArrayOfNumbers ( numberOfElements: number, startfromZero: boolean ): number[] {
    if ( numberOfElements === 0 ) {  return []; }
    const resultArray: number[] = [];
    const startAt: number = startfromZero ? 0 : 1;
    const endAt: number = startfromZero ? ( numberOfElements - 1 ) : ( numberOfElements );
    for ( let i = startAt;  i <= endAt; i++) {
      resultArray.push( i );
    }
    return resultArray;
  }

  public static getArrayOfNumbersByRange ( from: number, to: number ): number[] {
    const resultArray: number[] = [];
    for ( let i = from;  i <= to; i++) {
      resultArray.push( i );
    }
    return resultArray;
  }

  /***
   * Given a string in the 2018-01-17 10:55:05 format, it converts it into a ISO format (2018-01-17T10:55:05Z)
   * si it can be used to create a new Date object in JS
   * @param (string) notISOStringDate
   */
  public static createIsoDateTimeString( notISOStringDate: string ) {
    try {
      const arrayDate: string[] = notISOStringDate.split(' ');
      console.log( arrayDate );
      if ( arrayDate.length !== 2 ) { return null; }
      console.log( arrayDate[0] + 'T' + arrayDate[1] + 'Z' );
      return arrayDate[0] + 'T' + arrayDate[1] + 'Z';
    } catch ( error ) {
      console.log( 'UtilService.createIsoDateTimeString()' );
      console.error( error );
      return null;
    }
  }

  public static createDateFromNotISOStringDate( notISOStringDate: string ): Date {
    try {
      const ISOStringDate: string = UtilsService.createIsoDateTimeString( notISOStringDate );
      if ( isNullOrUndefined( ISOStringDate ) ) { return null; }
      return new Date ( ISOStringDate );
    } catch ( error ) {
      console.log( 'UtilService.createDateFromNotISOStringDate()' );
      console.error( error );
      return null;
    }
  }

  public static padRight( stringToPad: string, width: number, character = '0' ): string {
    try {
      return stringToPad.length >= width ?
        stringToPad : new Array(width - stringToPad.length + 1).join( character ) + stringToPad;
    } catch ( error ) {
      console.log( 'UtilService.pad()' );
      console.error ( error );
      return null;
    }
  }

  public static generateMonthDays( month: number, year: number ): MonthDay[] {
    try {
      const numberOfDaysForMonth: number = UtilsService.getNumberOfDaysForMonth( month, year );
      const resultMontDays: MonthDay[] = [];

      for ( let i = 1; i <= numberOfDaysForMonth; i++ ) {
        const itemMonthDay: MonthDay = { value: i, name: UtilsService.padRight( i.toString(), 2 ) };
        resultMontDays.push( itemMonthDay );
      }

      return resultMontDays;
    } catch ( error ) {
      console.log( 'UtilService.generateMonthDays()' );
      console.error( error );
    }
  }

  public static millisecondsCounter( initialDelay: number, millisecondsPeriod: number, until: number, ascendant: boolean ): Observable<number> {
    try {
      const counterResult: Observable<number> = TimerObservable.create( initialDelay, millisecondsPeriod )
        .map( ( counter: number ) => {
          if ( ascendant === true ) {
            return counter;
          } else {
            return until - counter - 1;
          }
        } ).catch( ( error: any ) => {
        console.log ( 'UtilService.counter()' );
        console.log( error );
        return Observable.throw( error );
      } );

      if ( until === 0 ) {
        return counterResult;
      } else {
        return counterResult.take( Math.abs( until ) );
      }

    } catch ( error ) {
      console.log ( 'UtilService.counter()' );
      console.log( error );
      Observable.throw( error );
    }
  }

}
