import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-code-verification',
  templateUrl: './code-verification.component.html',
  styleUrls: ['./code-verification.component.css']
})
export class CodeVerificationComponent implements OnInit {
  @Output() codeEntered: EventEmitter<string> = new EventEmitter<string>();
  show: boolean;


  constructor() {
    this.show = true;
  }

  ngOnInit() {
  }
  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    this.codeEntered.emit(code);
  }

  changeShow() {
    this.show = !this.show
  }
}
