import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import {Subscription} from 'rxjs/Subscription';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: []
})
export class HomeComponent implements OnInit {

  public env = environment;
  public subscription: Subscription;
  public codeRequest: string;

  private codeTemp: string;

  public dateToday: Date = new Date();
  public formatedDateToday = this.dateToday.getFullYear().toString() + '0' + (this.dateToday.getMonth() + 1) + '0' + this.dateToday.getDate();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.subscription = this.activatedRoute.params.subscribe( params => {
      this.codeTemp = params['code'];
    });
  }

  ngOnInit() {
    try {
      window.atob(this.codeTemp);
      // console.log('Si se pudo decodificar');
      this.codeRequest = window.atob(this.codeTemp);
      // console.log('Descodificado', this.codeRequest);
      const dateTemp = this.codeRequest.split(',');
      const dateTemp0 = window.atob(dateTemp[0]);
      // console.log('Fecha Desencriptada', dateTemp0);
      // console.log('separado', dateTemp);
      // console.log('formateado', this.formatedDateToday);
      // Validamos que el valor desencriptado sea igual a la fecha formateada del dia actual
      if ( dateTemp0 !== this.formatedDateToday ) {
        this.router.navigate(['error/400']);
      }
    } catch (e) {
      // something failed
      console.log('NO se pudo decodificar');
      // this.router.navigate(['error/400']);
    }

  }

}
