import { HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { GalavailRestService } from 'clientwsGalavail';
import { Observable, throwError } from 'rxjs';
import { PassengerType } from 'src/app/enums/passenger-type.enum';
import { CabinaItinerarioMenor } from 'src/app/models/cabina-itinerario-menor';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class CabinService {

  
  public static getPrice(): number {
    return 0;
  }

  constructor(
    private galavailRestServce: GalavailRestService,
  ) {
  }

  public getCabinas(itinerario: number, numberOfAdults: number, numberOfChildren: number): Observable<CabinaItinerarioMenor[]> {
    try {
      if (isNullOrUndefined(itinerario)
        || isNullOrUndefined(numberOfAdults)
        || isNullOrUndefined(numberOfChildren)) { throw new Error('Invalid pa'); }

      const urlParams: string = itinerario.toString() + '/' + numberOfAdults.toString() + '/' + numberOfChildren.toLocaleString();
      return this.galavailRestServce.request<CabinaItinerarioMenor[]>(
        urlParams,
        'request_itinerary_cabins',
        { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
      ).catch((error) => {
        return this.handleError(error);
      });
    } catch (error) {
      console.log('CabinaService.getCabinasss()');
      console.error(error);
      return this.handleError(error);
    }
  }
  public getAllCabins(itinerario: number, numberOfAdults: number,
    numberOfChildren: number, code: string): Observable<CabinaItinerarioMenor[]> {

    const body = this.jsonParamsCabins(itinerario, numberOfAdults, numberOfChildren, code)

    try {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json"
      });


      return this.galavailRestServce
        .request<CabinaItinerarioMenor[]>('', 'request_search_cabins', {
          headers: httpHeaders
        }, body)
        .do(() => {})
        .catch(error => {
          return this.handleError(error);
        });
    } catch (error) {
      if (isDevMode()) {
        console.log("CabinaService.getAllCabins()");
        console.error(error);
      }
      return this.handleError(error);
    }
  }
  private jsonParamsCabins(itinerario: number, numberOfAdults: number, numberOfChildren: number, code: string): any {
    return {
      adults: numberOfAdults,
      children: numberOfChildren,
      itinerary: itinerario,
      code,
    };
  }
  private handleError(error: any) {
    if (isDevMode()) {
      console.log('CabinaService.getCabinasss()');
      console.error(error);
    }
    return throwError(error);
  }

  public getAppPrice(price: number,
    cruiseCategory: number,
    promoCode: number,
    includeAirFare: boolean,
    passengerType: PassengerType,
    agencyPercent?: number

  ): number {


    if (!isNullOrUndefined(agencyPercent)) {
      return this.getPriceToPublic(price, includeAirFare, passengerType, (agencyPercent / 100));
    }
   
  }
  getPriceToPublic(price: number,
    includeAirFare: boolean,
    passengerType: PassengerType,
    agencyPercent?: number
  ) {
    let airFare = 0;
    const cruisePricePromo: number = price;
    /*

    if (includeAirFare) {
      let airFarePriceRate: PriceRateDescriptor;
      if (passengerType === PassengerType.CHILD) {
        airFarePriceRate = PriceRateDescriptor.ChildrenAirFare;
      } else {
        airFarePriceRate = PriceRateDescriptor.AirFare;
      }
      const priceRateAirFare: PriceRate = this.priceRateService.getPriceRateByName(airFarePriceRate);
      airFare = isNullOrUndefined(priceRateAirFare) ? 0 : priceRateAirFare.valor;
    }*/
    return Math.floor((cruisePricePromo ) + airFare);

  }
  private getPromoRate(): any {
    try {

    } catch (error) {
      console.log();
      console.error(error);
    }
  }

 
}
