import { Component, Input, OnInit } from '@angular/core';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  @Input() dataCompany: Company;
  constructor() { }

  ngOnInit() {
  }
  getSecundaryColor(): string {
    return "#" + this.dataCompany.secondaryColor;
  }

}
