import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GalavailServiceEcommer } from "clientwsGalavail";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { CompanyService } from "../../services/company/company.service";

@Component({
  selector: "app-company",
  templateUrl: "./company.component.html",
  styles: [],
})
export class CompanyComponent implements OnInit {
  private keyCompany: string;
  private requestCompany: any;
  public datesCompany: any;

  public subscription: Subscription;
  public empresa: string;

  public todayDate: number = new Date().getFullYear();
  public dateToday: Date = new Date();

  public colorPrimario: string;
  public colorSecundario: string;
  public colorCabecera: string;
  public colorPie: string;

  public mailTo: string;
  companyLogo = environment.appResource.companyLogos;
  constructor(
    private activatedRoute: ActivatedRoute,
    private galavailServiceEcommer: GalavailServiceEcommer,
    private router: Router,
    private companyService: CompanyService
  ) {
    this.subscription = this.activatedRoute.params.subscribe((params) => {
      this.empresa = params["company"];
    });
  }

  ngOnInit() {
    try {
      this.generateKeyCompany();

      this.galavailServiceEcommer.findCompany(this.requestCompany).subscribe(
        (response) => {
          this.datesCompany = response;
          this.mailTo = "mailto:" + this.datesCompany.infoEmail;
          this.companyService.setCompany(response);
          this.colorPrimario = "#" + this.datesCompany.mainColor;
          this.colorSecundario = "#" + this.datesCompany.secondaryColor;
          this.colorCabecera = "#" + this.datesCompany.headercolor;
          this.colorPie = "#" + this.datesCompany.footerColor;

          this.setScripts();
          this.setBodyScripts();
        },
        (error) => {
          console.log(error.error.date);
        }
      );
    } catch (error) {
      this.generateKeyCompany();

      this.galavailServiceEcommer.findCompany(this.requestCompany).subscribe(
        (response) => {
          this.datesCompany = response;
          this.mailTo = "mailto:" + this.datesCompany.infoEmail;
          this.companyService.setCompany(response);
          this.colorPrimario = "#" + this.datesCompany.mainColor;
          this.colorSecundario = "#" + this.datesCompany.secondaryColor;
          this.colorCabecera = "#" + this.datesCompany.headercolor;
          this.colorPie = "#" + this.datesCompany.footerColor;

          this.setScripts();
          this.setBodyScripts();
        },
        (error) => {
          console.log(error.error.date);
        }
      );
    }
  }

  public setScripts() {
    this.companyService.getScripts().forEach((item) => {
      var head = document.createElement("script");
      head.type = "text/javascript";
      head.src = item.srcLinks;
      document.head.appendChild(head);
    });
  }

  public setBodyScripts() {
    this.companyService.getBodyScripts().forEach((item) => {
      var body = document.createElement("script");
      body.type = "text/javascript";
      body.src = item.srcLinks;
      document.body.appendChild(body);
    });
  }
  public generateKeyCompany() {
    // const formatedDateToday: string = this.dateToday.getFullYear().toString() + '0' + (this.dateToday.getMonth() + 1) + '0' + this.dateToday.getDate();
    // const formatedDateToday: string = this.dateToday.getFullYear().toString() + formatMonth + formatDay;
    // const encodeDate = window.btoa(formatedDateToday);
    const encodeDate = window.btoa(this.getFormatedDateToday());
    console.log("encodeDate", encodeDate);
    console.log("Genera key en Componente Company");
    // const encodeCompany = window.btoa( encodeDate + ',royal' );
    const encodeCompany = window.btoa(encodeDate + "," + this.empresa);
    console.log("nombre EMPRESA", this.empresa);
    console.log("encodeCompany", encodeCompany);
    this.requestCompany = {
      key: encodeCompany,
    };
    return this.requestCompany;
  }

  getFormatedDateToday() {
    let formatedDateToday: string;
    const tempMonth: number = this.dateToday.getMonth() + 1;
    const tempDay: number = this.dateToday.getDate();

    let formatMonth: string;
    let formatDay: string;

    if (tempMonth > 0 && tempMonth < 10) {
      formatMonth = "0" + tempMonth;
    } else {
      formatMonth = tempMonth.toString();
    }
    if (tempDay > 0 && tempDay < 10) {
      formatDay = "0" + tempDay;
    } else {
      formatDay = tempDay.toString();
    }

    formatedDateToday =
      this.dateToday.getFullYear().toString() + formatMonth + formatDay;

    return formatedDateToday;
  }

  /**
   * Metodo que no ayuda a verificar si el color del pie es claro u oscuro,
   * esto se lo aplica para dar cierto estilos en el div
   * @param hexcolor
   */
  isTooDark(hexcolor: any) {
    if (hexcolor != null) {
      const r = parseInt(hexcolor.substr(1, 2), 16);
      const g = parseInt(hexcolor.substr(3, 2), 16);
      const b = parseInt(hexcolor.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      // Return new color if to dark, else return the original
      // return (yiq < 40) ? '#2980b9' : hexcolor;
      return yiq < 150;
    } else {
      return false;
    }
  }
}
