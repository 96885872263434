import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResultsComponent } from './components/results/results.component';
import {FormComponent} from './components/form/form.component';
import {HomeComponent} from './components/home/home.component';
import {BadRequestComponent} from './components/error-pages/bad-request/bad-request.component';
import {ServerErrorComponent} from './components/error-pages/server-error/server-error.component';
import {CompanyComponent} from './components/company/company.component';
import {ThanksComponent} from './components/form/thanks.component';
import { OnholdComponent } from './components/onhold/onhold.component';
import { ThanksOnholdComponent } from './components/onhold/thanks-onhold/thanks-onhold.component';

const routes: Routes = [
  // { path: '', component: ErrorComponent },
  { path: '', component: HomeComponent },
  { path: ':company', component: CompanyComponent },
  { path: ':company/:boat/:days/:monthYear/:paxs', component: ResultsComponent },
  // { path: 'inquire/:company/:boat/:promotion/:textPromotion/:priceRack/:pricePromo/:startDate/:endDate/:length/:opcLength/:paxs/:startEnd', component: FormComponent },
  /*{ path: 'inquire/:company/:code', component: FormComponent },
  { path: 'inquire/:company/:code/thanks', component: ThanksComponent },*/
  { path: ':company/:inquire/:code/:codeAvailability', component: FormComponent },
  { path: ':company/:code/thanks', component: ThanksComponent },
  { path: ':company/:code/thanks-onhold', component: ThanksOnholdComponent },
  // { path: ':code', component: HomeComponent },
  { path: 'error/400', component: BadRequestComponent },
  { path: 'error/500', component: ServerErrorComponent }
  // { path: '', redirectTo: 'error/400', pathMatch: 'full' },
  // { path: '**', redirectTo: 'error/400', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
