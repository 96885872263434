import { Component, OnInit, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'gal-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  animations: [
    trigger('dialog', [
      transition('void => *', [
        style({
          transform: 'translateY(-90vh)'
        }),
        animate(300)
      ]),
      transition('* => void', [
        animate(300, style({
          transform: 'translateY(-90vh)',
        }))
      ])
    ])
  ]
})
export class ModalComponent implements OnInit {
  // Inputs
  @Input() closable = true;
  @Input() cancelable = true;
  @Input() visible: boolean;
  @Input() closeColor: string = '#2d2d2d';
  @Input() modalWidth: string = '50vw';
  @Input() modalMinHeight: string = '200px';
  @Input() modalTop: string = '110px';

  // Outputs
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() modalClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  animationEnded(event){
    if(!this.visible){
      this.modalClosed.emit(this.visible);
    }
  }

}

