import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'avagalavail';
  public searchModel: {
    month: number;
    year: number;
    adults: number;
    children: number;
    company?: number;
    cruiseCategory?: any;
    cruiseType?: any;
    tourLength?: any;
    order?: any;
    page: number;
    cruise?: any;
  };
  constructor(

  ) { }
}
